const _ = require('lodash');

const axios = require('axios');

axios.defaults.headers.common['Access-Control-Request-Methods'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (_.get(error, 'response.status') === 401 && originalRequest.url.split('/').pop() !== "login") {
      if (originalRequest._retry) {
        localStorage.clear();
        return window.location.replace(`${window.location.origin}/login`);
      }
      originalRequest._retry = true;
      // Token Refresh and Retry logic to be added here.
      return axios(originalRequest);
    } else {
      return Promise.reject(error);
    }
  }
)

module.exports = axios;