import { createStore, applyMiddleware } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from 'redux-saga'

// Encrypt Redux store.
import { encryptTransform } from 'redux-persist-transform-encrypt';

import reducer from './reducers/index'
import saga from './saga'

import browser from 'browser-detect';

const secretKeyGenerator = () => {
    let { name, version, os } = browser();
    let timestamp = localStorage.getItem('timestamp');
    if (!timestamp) {
        timestamp = new Date().getTime();
        localStorage.setItem('timestamp', timestamp);
    }
    return `${timestamp}-${name}-${version}-${os}`
}

const encryptionConfig = encryptTransform({
    secretKey: secretKeyGenerator(),
    onError: (error) => {
        console.log(`Security issue breached while encrypting!`, error);
    }
})

const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptionConfig]
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)))

sagaMiddleware.run(saga)

const persister = persistStore(store);

const configureStore = () => ({ store, persister });

export default configureStore;
