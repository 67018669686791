import React from 'react';
import '../InputSelect/style.css'

let Input = (props) => {

    let { values,
        label,
        name,
        value,
        placeholder="",
        isImportant,
        onChange,
        style,
        errorState,
        errorMsg,
        disabled=null,
        required=null
    } = props

    return (
        <div className='input-select-wrapper '>
            <p className='default-label-style'>{label}{isImportant ? <span className='impAsterisk'>*</span> : null}</p>
            <select value = {value}  style={style} name={name} className='default-input-style' id="" onChange={(e) => onChange(e)} required={required} disabled={disabled}>
                <option value="" >{placeholder}</option>
                {values.map((option) => <option value={option}>{option}</option>)}
            </select>
            {errorState && <div className='Error'>{errorMsg}</div>}
        </div>
    )
}

export default Input