import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

export const getProductColumns = ({ onEdit }) => {
   return [
        {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: false,
        },
        {
            name: 'Customer Email',
            selector: row => row.customerEmail,
            sortable: false,
        },
        {
            name: 'website',
            selector: row => row.website,
            sortable: false,
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: false,
        },
        {
            name: 'Edit',
            selector: row => <p><a className="editTag" onClick={() => onEdit(row)}><FontAwesomeIcon icon={faEdit} /></a></p>,
            sortable: false,
        },
    ]
};

export const generateCustomerDetailsPayload = (payload) => {

    const { state, onValueChange } = payload;
    const { customerName, customerEmail, isGst, gstNo, panNumber, pinCode, address, phone, website, notes } = state;

    let customerDetails = [
        { name: "customerName", inputType: "input", type: "text", value: customerName, label: "Customer Name", placeholder: "Enter Customer Name" , onValueChange},
        { name: "customerEmail", inputType: "input", type: "text", value: customerEmail, label: "Customer Email", placeholder: "Enter Customer Email", onValueChange },
        { name: "isGst", important: true, type: "dropdown", values: ["Yes", "No"], value: isGst, label: "GST Registered", placeholder: "Select GST Registered", onValueChange: onValueChange, inputType: "dropdown" },
        { name: "address", type: "text", value: address, label: "Address", placeholder: "Enter Address", inputType: "input", onValueChange },
        { name: "pinCode", type: "number", value: pinCode, label: "Pin Code", placeholder: "Enter Pin Code", inputType: "input" , onValueChange },
        { name: "phone", type: "number", value: phone, label: "Phone", placeholder: "Enter Phone Number", inputType: "input" , onValueChange },
        { name: "website", type: "text", value: website, label: "Web Page", placeholder: "Enter Web Page", onValueChange: onValueChange, important: true, inputType: "input" },
        { name: "notes", type: "text", value: notes, label: "Notes", placeholder: "Enter Notes", onValueChange: onValueChange, important: true, inputType: "input" }
    ];

    let isGstRegistered = { name: "panNumber", type: "text", value: panNumber, label: "PAN Number", placeholder: "Enter PAN Number", inputType: "input" , onValueChange };
    if (isGst === "Yes") isGstRegistered = { name: "gstNo", type: "text", value: gstNo, label: "GSTIN", placeholder: "Enter GSTIN", inputType: "input" , onValueChange };

    customerDetails.splice(3, 0, isGstRegistered);

    return customerDetails;

}