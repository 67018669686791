import React, { Component, Fragment } from 'react';

import "./style.css"

import DataTable from '../../Components/DataTable/index';

import { getProductColumns , generateCustomerDetailsPayload } from "./formPayload";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';

import { renderProductForm } from './common';

import { renderGrowlUI, showGrowlMessage } from '../../Services/index';

import { updateCustomers, fetchCustomers } from '../../utils/endPoints';

export default class Customers extends Component {

    state = {
        page: 1,
        newPerPage: 10
    }


    componentDidMount = () => this.triggerListOfProducts();

    triggerListOfProducts = () => {

        const { newPerPage, page } = this.state;

        this.fetchUsers(newPerPage, page);
    }

    applyFilter  = () => {
        const { searchTerm } = this.state;

        if (!searchTerm) return showGrowlMessage({ growlMsg: "Search Customers By Customer Name", growlStatus: "error", updateState: this.updateState });

        this.triggerListOfProducts();
    }

    fetchUsers = async (newPerPage, page) => {

        this.setState({ loading: true });

        const { searchTerm } = this.state;

        const { result = [], totalRows = 10 } = await fetchCustomers({ pageNumber: page, limit: newPerPage , searchTerm });

        this.setState({ data: result, totalRows, loading: false });
    }

    onChangePage = (page) =>  this.fetchUsers(10, page);

    onChangeRowsPerPage = (newPerPage, page) => this.fetchUsers(newPerPage, page);

    onFilterValueChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    onValueChange = (event) => { this.setState({ [event.target.name]: event.target.value }) };

    updateProductDetails = async () => {

        const isValidData = this.validateCustomerDetails(this.state);

        if (isValidData) return showGrowlMessage({ growlMsg: "Kindly Provide All the Mandatory Fields", growlStatus: "error", updateState: this.updateState });

        this.updateState({ visible: false, loading: true });

        try {

            const { customerName = "", customerEmail = "", isGst = "", gstNo = "", panNumber = "", pinCode = "", address = "", phone = "", website = "", notes = "",  } = this.state;

            const payload = { customerName, customerEmail, isGst, gstNo, panNumber, pinCode, address, phone, website, notes };

            const { status = false , message = ""} = await updateCustomers(payload);

            if (status) {
                this.triggerListOfProducts();
                return showGrowlMessage({ growlMsg: "Customer Created Successfully", growlStatus: "success", updateState: this.updateState });
            }

            showGrowlMessage({ growlMsg: message, growlStatus: "error", updateState: this.updateState });

        } catch (error) {
            showGrowlMessage({ growlMsg: "Unable to Create Customer", growlStatus: "error", updateState: this.updateState });
        } finally {
            this.clearAllFormFields();
        }
    }

    validateCustomerDetails = (state) => {

        const { customerName = "", customerEmail = "", isGst = "", gstNo = "", panNumber = "", pinCode = "", address = "", phone = "", website = "", notes = "", } = state;

        const mandatoryList = [customerName, customerEmail, isGst, pinCode, address, phone, website, notes];

        isGst === "Yes" ? mandatoryList.push(gstNo) : mandatoryList.push(panNumber);

        return mandatoryList.includes("");
    }

    updateState = (state) => this.setState(state);

    clearAllFormFields = () => {
        this.updateState({
            isEdit: false, loading: false, visible: false, customerName: "", customerEmail: "",
            isGst: "", gstNo: "", panNumber: "", pinCode: "", address: "", phone: "", website: "", notes: "",
        });
    }

    renderUploadModel = () => {

        const { visible , isLoading = false , customerId } = this.state;

        return <Modal id="model" show={visible} size="lg" centered effect="fadeInUp"  onHide={() => this.clearAllFormFields()}>
            {renderGrowlUI(this.state, this.updateState)}
            <div className="model-file-upload-height">
                <div className="model-file-upload-header-1">
                    <div className="invoiceInfoHeading">CUSTOMER</div>
                    <div className="invoiceInfoHeading" onClick={() => this.clearAllFormFields()}><a>X</a></div>
                </div>
                <div className="model-file-upload-flex flex-col">
                    <div className="login-form extraPadding">
                        {generateCustomerDetailsPayload({ state: this.state, onValueChange: this.onValueChange }).map(eachField => renderProductForm(eachField.inputType)(eachField))}
                    </div>
                    <div className="addNewProductButtonUI bottomUI">
                        <button className="addNewProductButton" onClick={() => this.clearAllFormFields()}> Cancel</button>
                        <button className="addNewProductButton" onClick={() => !isLoading && this.updateProductDetails()}>
                            <div> {customerId ? "Update" : "Save"}</div>{isLoading && <div className='lds-dual-ring loaderMargin'></div>}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    }

    onEdit = (row) => {
        const { customerId, customerName, customerEmail, isGst, gstNo, panNumber, pinCode, address, phone, website, notes } = row;
        this.setState({ visible : true, customerId, customerName, customerEmail, isGst, gstNo, panNumber, pinCode, address, phone, website, notes});
    }
    render() {
        const { data = [] , totalRows = 0 , loading = false } = this.state;
        return (
            <Fragment>
                {this.renderUploadModel()}
                {renderGrowlUI(this.state, this.updateState)}
                <div className="layout-alignment dataTableMargin" id="dataTable">
                    <div className="addNewProductButtonUI">
                        <h2> Customers</h2>
                        <button className="addNewProductButton" onClick={() => this.setState({ visible: true, isEdit: false })}> <FontAwesomeIcon icon={faEdit} /> Add New Customer</button>
                    </div>
                    <div className="">
                        <DataTable title={`Manage Customers (${totalRows})`}
                            onFilterValueChange={this.onFilterValueChange} selectableRows={true} data={data} columns={getProductColumns({ onEdit: this.onEdit })} totalRows={totalRows}
                            loading={loading} onChangePage={this.onChangePage} applyFilter={this.applyFilter}
                            refreshFilter={this.triggerListOfProducts} subHeader={true} searchBy={"Customers"}
                            onChangeRowsPerPage={this.onChangeRowsPerPage} pagination={true} />
                    </div>
                </div>
            </Fragment>
        )
    }
}
