export const enums = {
    CHANGE: "Change",
    FORGOT: "Forgot",
    ERROR: "error",
    SUCCESS: "success",
    PASSWORD: "Password",
    EMAIL: "email",
    LINK_EXPIRED: "Reset Password Link Expired",
    EMAIL_ERROR_MESSAGE: "Kindly Enter Valid Email ID",
    PASSWORD_MIN_LENGTH_ERROR: "Password should be minimum of 8 characters",
    PASSWORD_MIS_MATCH_ERROR: "The Passwords Should Match",
    RESET_PASSWORD_INSTRUCTION: "Please enter your password below.",
}