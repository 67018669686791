import actionTypes from '../type/index';

const {
    LOGOUT_USER,
    SET_USER_INFO,
    UPDATE_USER_INFO,
    SET_AUTH_TOKEN,
} = actionTypes;

export const setUserInfo = payload => {
    return {
        type: SET_USER_INFO,
        payload
    }
}

export const updateUserInfo = payload => {
    return {
        type: UPDATE_USER_INFO,
        payload
    }
}

export const setAuthToken = payload => {
    return {
        type: SET_AUTH_TOKEN,
        payload
    }
}

export const logOut = () => {
    return {
        type: LOGOUT_USER
    }
}