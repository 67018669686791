import React, { Fragment } from 'react';
import TextInput from '../../../../Components/FormComponents/TextInput'
import InputSelect from '../../../../Components/FormComponents/InputSelect';
import Loader from "react-loader-spinner";
import FileInput from '../../../../Components/FormComponents/FileInput';
import LOADER from "../../../../utils/loader";
import Button from '../../../../Components/FormComponents/Button'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const { statePayload, ceoDetailsPayload, logoDetailsPayload, countryPayload, companyDetailsPayload, sectorPayload, 
    companyMetaDetailsPayload , teamSizePayload , getBusinessInfo } = require("../Forms/formPayload");

export const renderTextAreaFields = (fields) => {
    const { name, type, value, label, placeholder, errorState, errorMsg , important , onValueChange } = fields;
    return <div className='common-field-margin-top'>
        <label className={'default-label-style'} >{label} {important ? <span className='impAsterisk'>*</span> : null}</label>
        <textarea className="textAreaBox" rows="4" cols="50" name={name} type={type} value={value} label={label} isImportant={true} onChange={(e) => onValueChange(e)} placeholder={placeholder} />
        {errorState && <div className='Error'>{errorMsg}</div>}
    </div>
}
export const renderDropDown = (fields) => {
    const { name, type, label, values, placeholder, errorState, errorMsg, value , important , onValueChange, disabled=null } = fields;
    return <Fragment>
        <InputSelect type={type} name={name} isImportant={important} errorState={errorState} errorMsg={errorMsg} placeholder={placeholder} onChange={(e) => onValueChange(e)} value={value} values={values} label={label} disabled={disabled} />
    </Fragment>
}

export const renderInputFields = (fields) => {
    const { name, type, value, label, placeholder, errorState, errorMsg , important , onValueChange, disabled=null } = fields;
    return <div className='common-field-margin-top'>
        <TextInput name={name} type={type} value={value} label={label} isImportant={important} onChange={(e) => onValueChange(e)} placeholder={placeholder} disabled={disabled} />
        {errorState && <div className='Error'>{errorMsg}</div>}
    </div>
}

export const renderLoader = () => {
    return <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000}
    />
}

export const renderCEOInputFields = (fields) => {
    const { name, type, value, label, placeholder, errorState, errorMsg, important, onValueChange } = fields;
    return <Fragment>
        {name === "ceoName" && <h3 className="paddingCEO"> Company Representative</h3>}
        {type === "string" ? <div className='common-field-margin-top'>
            <TextInput name={name} type={type} value={value} label={label} isImportant={important} onChange={(e) => onValueChange(e)} placeholder={placeholder} />
            {errorState ? <div className='Error'>{errorMsg}</div> : null}
        </div> : renderDropDown(fields)}
    </Fragment>
}

export const renderFileUpload = (fields) => {
    const { name, type, label, placeholder, errorState, errorMsg, fileType, value , important , onFileUpload , removeUploadedFile } = fields;
    return <Fragment>
        <FileInput name={name} type={type} label={label} isImportant={important} placeholder={placeholder} fileType={fileType} errorState={errorState} errorMsg={errorMsg} onChange={(e) => onFileUpload(e)}  onClick={(e) => { removeUploadedFile(e)}} value={value} />
    </Fragment>
}

export const renderAutoComplete = (fields) => {
    const { values, name, value, isImportant, label, onValueChange , placeholder } = fields;
    return <div className='input-select-wrapper ' id="autoComplete">
        <p className='default-label-style'>{label}{isImportant ? <span className='impAsterisk'>*</span> : null}</p>
        <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={values.map((option) => option)}
            renderInput={(params) => (
                <TextField {...params} placeholder={placeholder} name={name} onChange={(e) => onValueChange(e)} value={value} />
            )}
        />
    </div>
}

export const renderStateUI = (fields) => {
    const { state, onValueChange } = fields;
    const stateDetails = statePayload(state);
    return <div className='common-field-margin-top field-with-rows'>
        {stateDetails.map(field => field.type === "autoComplete" ? renderAutoComplete({ ...field, onValueChange }) :
            renderDropDown({ ...field, onValueChange }))}
    </div>
}

export const renderCountyUI = (fields) => {
    const { state, onValueChange } = fields;
    const { country } = state;
    const countryList = ["India"];
    const countryForm = countryPayload(state);
    return <div className='common-field-margin-top field-with-rows'>
        {renderDropDown({ ...countryForm, values: countryList, value: country , onValueChange })}
    </div>
}

export const renderCeoLayout = (fields) => {
    const { state, onValueChange } = fields;
    const details = ceoDetailsPayload(state);
    return <div className='common-field-margin-top'>
        {details.map(field => renderCEOInputFields({...field , onValueChange}))}
    </div>
}

export const renderLogoDetails = (fields) => {
    const { state, onFileUpload , removeUploadedFile } = fields;
    const details = logoDetailsPayload(state);
    return <div className='common-field-margin-top field-with-rows justify-space-between'>
        {details.map(field => renderFileUpload({ ...field, onFileUpload, removeUploadedFile }))}
    </div>
}

export const renderCompanyDetails = (fields) => {

    const { state, onValueChange } = fields;

    const companyDetails = companyDetailsPayload(state);

    return companyDetails.map(field => renderInputFields({ ...field, onValueChange }));
}

export const renderCompanyMetaData = (fields) => {

    const { state, onValueChange } = fields;

    const companyMetaDetails = companyMetaDetailsPayload(state);

    return companyMetaDetails.map(field => renderTextAreaFields({ ...field, onValueChange }));
}

export const renderSectorDetails = (fields) => {

    const { state, onValueChange } = fields;

    const sectorDetails = sectorPayload(state);

    return <div className='common-field-margin-top field-with-rows'>{sectorDetails.map(field => renderDropDown({ ...field, onValueChange }))}</div>
}


export const renderTeamSize = (fields) => {

    const { state, onValueChange } = fields;

    const { error , employeeCount } = state;

    return <div className='common-field-margin-top'>
        {renderDropDown({
            ...teamSizePayload(employeeCount), onValueChange
        })}
        {error.employeeError && <div className='Error'>Please Select The Number of Employees</div>}
    </div>
}

export const renderBusinessInfo = (fields) => {

    const { state, onValueChange } = fields;

    const { error } = state;

    const businessPayload = getBusinessInfo(state);

    return businessPayload.map(each => <div className='common-field-margin-top'>
        {renderDropDown({ ...each, onValueChange })}
        {error[`${each.name}Error`] && <div className='Error'>{each.errorMsg}</div>}
    </div>)
}

export const renderCompanyLayout = (fields) => {

    const { state, onValueChange } = fields;

    return <div>
        {renderCompanyDetails({ state, onValueChange })}
        {renderBusinessInfo({ state, onValueChange })}
        {renderCompanyMetaData({ state, onValueChange })}
        {/* {renderSectorDetails({ state, onValueChange })} */}
        {renderTeamSize({ state, onValueChange })}
    </div>
}


export const renderCEOLayout = (fields, showCeoLayout=true) => {

    const { state, onValueChange, props, updateState , onFileUpload , removeUploadedFile} = fields;

    return <div>
        
        {renderCountyUI({ state, onValueChange })}

        {renderStateUI({ state, onValueChange })}

        {renderLogoDetails({
            state, onFileUpload: (event) => onFileUpload({ event, props, updateState }),
            removeUploadedFile: (event) => removeUploadedFile({ props, state, updateState, event })
        })}

        {showCeoLayout && renderCeoLayout({ state, onValueChange })}
    </div>
}


export const renderSubmitButton = (fields) => {

    const { state, onPress, className, borderClassName } = fields;

    const { fileLoader, loader } = state;

    return <div className={borderClassName ? `${borderClassName} common-button-margin-top` : "common-button-margin-top"}>
        {(fileLoader || loader) ? <LOADER /> : <Button className={className} onPress={onPress} name='Save And Proceed' />}
    </div>
}