import React from 'react';
import '../FileInput/style.css'

let Input = (props) => {

    let { 
        fileType,
        label,
        name,
        isImportant,
        onChange,
        errorState,
        errorMsg,
        onClick,
        value
    } = props

    const fileInput = React.createRef();

    let fileName = value && value.split('-').pop();

    return (
        <div className="reg-form-file-input">
            {label &&
                <label className={"default-label-style"} >{label}{isImportant && <span className='impAsterisk'>*</span>}</label>}
            <div className='file-input-wrapper'
                onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.effectAllowed = 'move';
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    let droppedFiles = e.dataTransfer.files;
                    e.target = fileInput.current;
                    e.target.files = droppedFiles;
                    onChange(e);
                }}
                onClick={(e) => {
                    fileInput.current.click();
                }}>
                {
                    value ? <div className="file-input-uploaded">{fileName}</div> : <div className="file-input-blank">Choose File</div>
                }
                <input className="file-input-hidden" type='file' name={name} accept={fileType} onClick={(e) => onClick(e)} onChange={(e) => { onChange(e)  }} ref={fileInput} />
            </div>
            {errorState && <div className='Error'>{errorMsg}</div>}
        </div>
    )
}

export default Input