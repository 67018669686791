
import _ from 'lodash';

import { setAuthToken, setUserInfo } from '../redux/actions/index';

import { store } from '../redux/store';

const TOKEN = 'AuthToken';

export const storeToken = (props, token) => {
    return props.dispatch(setAuthToken(token));
}

export const getToken = () => {
    const { loginReducer = {} } = store.getState();
    return _.get(loginReducer, TOKEN);
}

export const getUserEmailID = () => {
    const { loginReducer = {} } = store.getState();
    return _.get(loginReducer, "User.email");
}

export const storeUserData = (props, userDetails) => {
    return props.dispatch(setUserInfo(userDetails));
}

export const getUserData = () => {
    const { loginReducer = {} } = store.getState();
    return _.get(loginReducer, "User");
}

export const getShortName = () => {

    const { loginReducer = {} } = store.getState();

    return _.get(loginReducer, "User.role.shortName");
}

export const getUserPermission = () => {
    const { loginReducer = {} } = store.getState();
    return _.get(loginReducer, "User.isAdmin");
}

export const getCompanyId = () => {

    const { loginReducer = {} } = store.getState();

    return _.get(loginReducer, "User.company");
}
