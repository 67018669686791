import React, { Component, Fragment } from 'react';

import { changeUserPassword, forgotUserPassword } from "../../utils/endPoints";

import { getOrganizationID } from '../../utils/utils';

import { isInvalid } from '../../utils/validations';

import Loader from "../../utils/loader";

import { renderGrowlUI, showGrowlMessage } from '../../Services/index';

import { fetchTokenFromReCaptchaV3 } from '../../utils/recaptchav3';


const { enums } = require("./service/enums");

const RE_DIRECTION_TIMEOUT = 500;

const { validateConfirmPassword, validateMinPasswordLength } = require("../ForgotPassword/service/index");

export default class ForgotPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            confirmPassword: "",
            password: "",
            text: "",
            loader: false
        };
    }

    renderEmptyContainer = () => <div></div>

    renderLineSpace = () => <Fragment><br></br></Fragment>

    renderLogo = () => <img className='forgotImage' src={"https://cdn.dribbble.com/users/7523955/screenshots/15709851/media/3a333103b7e71d00d239b30ecd995178.jpg"} alt='logo' />

    onValueChange = event => this.updateState({ [event.target.name]: event.target.value });

    componentDidMount() { this.fetchTokenFromUrl(window.location.pathname); }

    fetchTokenFromUrl = (pathName) => {
        if (pathName.includes("/change-password")) {
            const encryptedData = pathName.split("/change-password/") &&
                pathName.split("/change-password/").length && pathName.split("/change-password/")[1];
            if (!encryptedData) { return window.location.href = `${window.location.origin}/login`; }
            this.updateState({ encryptedData, text: enums.CHANGE });
            return;
        }
        this.updateState({ text: enums.FORGOT });
    }

    validateForm = (text) => {

        const { password, email , confirmPassword } = this.state;

        // Change Password Flow
        if (text === enums.CHANGE) {
            if (!(validateMinPasswordLength(password))) {
                return showGrowlMessage({ growlMsg: enums.PASSWORD_MIN_LENGTH_ERROR, growlStatus: enums.ERROR, updateState: this.updateState });
            }
            if (!(validateConfirmPassword(password , confirmPassword))) {
                return showGrowlMessage({ growlMsg: enums.PASSWORD_MIS_MATCH_ERROR, growlStatus: enums.ERROR, updateState: this.updateState });
            }
            this.changePassword();
        }

        // Forgot Password Flow
        if (text === enums.FORGOT) {
            if (isInvalid(enums.EMAIL, email)) { return showGrowlMessage({ growlMsg: enums.EMAIL_ERROR_MESSAGE, growlStatus: enums.ERROR, updateState: this.updateState }); }
            this.forgotPassword();
        }
    }

    forgotPassword = async () => {

        const { email } = this.state;

        this.updateState({ loader: true });

        try {

            const result = await forgotUserPassword({ email, organizationId: getOrganizationID() , googleToken: await fetchTokenFromReCaptchaV3() });

            this.updateState({ loader: false });

            let { status = false ,  message = ""} = result;

            if (status) {
                showGrowlMessage({ growlMsg: message, growlStatus: enums.SUCCESS, updateState: this.updateState });
                this.reDirectToLoginPage();
            } else {
                showGrowlMessage({ growlMsg: message, growlStatus: enums.ERROR, updateState: this.updateState });
            }
        } catch (error) {
            this.updateState({ loader: false });
        }
    }

    changePassword = async () => {

        const { encryptedData, password } = this.state;

        this.updateState({ loader: true });

        try {

            const result = await changeUserPassword({ password , googleToken: await fetchTokenFromReCaptchaV3() }, encryptedData);

            this.updateState({ loader: false });

            let { status = false ,  message = ""} = result;

            if (status) {
                showGrowlMessage({ growlMsg: message, growlStatus: enums.SUCCESS, updateState: this.updateState });
                this.reDirectToLoginPage();
            } else {
                showGrowlMessage({ growlMsg: message, growlStatus: enums.ERROR, updateState: this.updateState });
            }
        } catch (error) {

            showGrowlMessage({ growlMsg: enums.LINK_EXPIRED, growlStatus: enums.ERROR, updateState: this.updateState });

            this.updateState({ loader: false });
        }
    }

    // Re Direct to Login Page
    reDirectToLoginPage = () => {
        setTimeout(() => {
            this.props.history.replace('/login');
        }, RE_DIRECTION_TIMEOUT);
    }

    renderInput = (inputFields) => {
        const { label, name, type, placeholder } = inputFields;
        return <div>
            <div className="forgotFlex">
                {/* <div className=""> <div className="forgotFlexPad">{label}</div></div> */}
                <div className=""> <input name={name} className="forgotInputBox" type={type} placeholder={placeholder} onChange={this.onValueChange}></input></div>
            </div>
        </div>
    }


    renderLayout = (text) => {
        return <Fragment>
            {this.renderLineSpace()}
            {text === enums.FORGOT ? 
            this.renderInput({ label: 'Enter your email address', name: "email", placeholder: "support@google.com", type: "email" }) 
            : this.renderConfirmPassword()}
            {this.renderLineSpace()}
        </Fragment>
    }

    renderConfirmPassword = () => {
        return <Fragment>
            {this.renderInput({ label: 'Password', name: "password", placeholder: "Enter Password", type: "password" })}
            {this.renderInput({ label: 'Confirm Password', name: "confirmPassword", placeholder: "Confirm Password", type: "password" })}
        </Fragment>
    }

    renderSubmitButton = (text) => {
        const label = text === enums.FORGOT ? "SEND" : `${text} ${enums.PASSWORD}`;
        return <div className="">
            {this.state.loader ? <Loader /> : <div className="comButton" onClick={() => { this.validateForm(text) }}>  {label}</div>}
        </div>
    }

    updateState = (state) => this.setState(state);

    renderMainLayout = () => {
        const { text } = this.state;
        return <div className="forgotForm">
            <div className="textCenter forgotLogoContainer">
                {this.renderLogo()}
            </div>
            <div className="forgotPasswordContent">
                <div className="">
                    <h1> {text === enums.FORGOT ? "Forgot password ?" : "Change Password"}</h1>
                    {text === enums.FORGOT && <h5>{enums.RESET_PASSWORD_INSTRUCTION}</h5>}
                </div>
                {this.renderLayout(text)}
                {this.renderSubmitButton(text)}
                <div className="common-field-margin-top">
                    <p className="css-1h15lvr">Back to login page? <a href="/login"><span className="css-evbtna">Login</span></a></p>
                </div>
            </div>
        </div>
    }
    render() {
        return (
            <div className='mainContainer'>
                {renderGrowlUI(this.state, this.updateState)}
                <div className="spaceBetween" style={{ height: '100%', width: '100%' }}>
                    {this.renderEmptyContainer()}
                    {this.renderMainLayout()}
                    {this.renderEmptyContainer()}
                </div>
            </div>
        )
    }
}
