import React, { Fragment } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown , faRedoAlt , faEdit } from '@fortawesome/free-solid-svg-icons';
import { enums } from "./enums";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { generBasicInvoiceInfo , generateSellerInfo , generateInvoicePreviewPayload } from "./formPayload";
import _ from 'lodash';
import { showGrowlMessage } from '../../Services/index';
import { renderProductForm } from '../Customers/common';
import Modal from 'react-bootstrap/Modal';
import AutocompleteUI from '../../Components/Autocomplete/index';
import MaterialUIPickers from '../../Components/DatePicker/index';
const { fetchCompany , fetchCustomer , createInvoice , updateInvoice } = require("../../utils/endPoints");

export const renderAccordionSummary = (inputFields) => {
    const { icon, title } = inputFields;
    return <AccordionSummary >
        <Typography>
            <FontAwesomeIcon className="MenuIcon iconColor iconFont" icon={icon} />{title}</Typography>
        <div className="flexRow">
            <div className="expandText">{enums.EXPAND}</div>
            <FontAwesomeIcon className="MenuIcon expandIcon" icon={faSortDown} />
        </div>
    </AccordionSummary>
}

export const renderSellerInfo = (formInfo = {}) => {
    const { label, value } = formInfo;
    return <div className="flex-col">
        <label>{label}</label>
        <span className="seller-value">{value || ""}</span>
    </div>
}

export const renderBillsInfo = (formInfo = {}) => {
    const { label, value } = formInfo;
    return <div className="flex-col">
        <label>{label}</label>
        <span className="seller-value font14">{value || ""}</span>
    </div>
}

export const renderInvoiceSummaryRow = (label, value) => {
    const { formatIndianNumberFormat } = require("../Sales/service/index");
    return <div className="space-between">
        <div className="seller-value">{label}</div>
        <div className="seller-value">{formatIndianNumberFormat(value)}</div>
    </div>
}

export const renderRemarksUI = (payload) => {
    const { state, onValueChange } = payload;
    const { remarks } = state;
    return <div className="fullWidth">
        <div className="seller-value">REMARKS</div> <br></br>
        <div>
            <textarea className="textAreaBox" type="number" name="remarks" value={remarks} onChange={onValueChange}></textarea>
        </div>
    </div>
}
export const renderInvoiceSummarySmallRow = (label, value) => {
    const { formatIndianNumberFormat } = require("../Sales/service/index");
    return <div className="space-between">
        <div className="extra-tax-label">{label}</div>
        <div className="extra-tax-label"> {formatIndianNumberFormat(value)}</div>
    </div>
}

export const renderInvoiceSummarySmallInputRow = (label, value , onChange) => {
    return <div className="space-between">
        <div className="extra-tax-label">{label}</div>
        <div className="extra-tax-label flex"> <input className="default-input-style" type="number" name="advanceAmount" value={value} onChange={onChange}></input></div>
    </div>
}

export const getSellerInfoData = (sellerInfo) => {
    const { businessName = "", gstNo = "", address = "", website = "", phone = "", email = "" } = sellerInfo || {};
    return [
        {
            name: "businessName",
            value: businessName,
            label: "Business Name:"
        },
        {
            name: "gstNo",
            value: gstNo,
            label: "GSTIN:"
        },
        {
            name: "address",
            value: address,
            label: "Address:"
        },
        {
            name: "website",
            value: website,
            label: "Website:"
        },
        {
            name: "phone",
            value: phone,
            label: "Phone:"
        },
        {
            name: "email",
            value: email,
            label: "Email:"
        },
    ]
}

export const renderInvoicePreviewTable = (tableData = []) => {
    const { formatIndianNumberFormat } = require("../Sales/service/index");
    return <div className="billPadding">
        <table>
            <tr>
                <th>ITEM</th>
                <th>DESCRIPTION</th>
                <th>QUANTITY</th>
                <th>PRICE</th>
                <th>DISCOUNT(%)</th>
                <th>TAX(%)</th>
                <th>TOTAL</th>
            </tr>
            {tableData.map(each => <tr>
                <td>{each.item}</td>
                <td>{each.description}</td>
                <td>{each.quantity}</td>
                <td>{formatIndianNumberFormat(each.price)}</td>
                <td>{each.discountRate}</td>
                <td>{each.taxRate}</td>
                <td>{formatIndianNumberFormat(each.total)}</td>
            </tr>)}
        </table>
    </div>
}

export const renderBillingAddressInfo = (state) => {
    const { buyerInfo = {}, sellerInfo = {} } = state;
    return <div className="flex">
        {renderCommonSellerUI(sellerInfo, 'BILL FROM')}
        {renderCommonSellerUI(buyerInfo, 'BILL TO')}
    </div>
}

export const renderCommonSellerUI = (info = {}, label = "") => {
    const { businessName, gstNo, email, address } = info;
    return <div className="flex-col billPadding" id="center">
        <span className="seller-value">{label}</span>
        <br></br>
        <span className="invoicePopCompanyDetails">{businessName}</span>
        <span className="invoicePopCompanyDetails">{gstNo}</span>
        <span className="invoicePopCompanyDetails">{email}</span>
        <span className="invoicePopCompanyDetails">{address}</span>
    </div>
}

export const commonLabelPreview = (fields) => {
    const { formatIndianNumberFormat } = require("../Sales/service/index");
    const { label, value, isBackground = false } = fields;
    return <div className={isBackground ? "flex space-between billTotalBg" : "flex space-between"}>
        <label className="">{label}</label>
        <div className="seller-value">{formatIndianNumberFormat(value)}</div>
    </div>
}

export const generateInvoice = () => {
    html2canvas(document.querySelector("#invoiceCapture"), { allowTaint : true, useCORS:true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [612, 792]
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('invoice-001.pdf');
    });
}

// https://onlinedevtools.in/assets/img/angular.png
export const renderInvoicePreviewBacisInfo = (state) => {
    const { sellerInfo = {} } = state;
    return <div className="flex login-space-between">
        <div> <img className="invoiceCaptureimg" src="https://kernel-storage.fra1.cdn.digitaloceanspaces.com/kernel_company_logos/logo_40529_839dff35-e3fb-11ec-8000-000000000000.png" /></div>
        <div className="flex-col" id="center">
            <span className="seller-value">{sellerInfo.businessName || ''}</span>
            <br></br>
            {generBasicInvoiceInfo(state).map((each) => renderBillsInfo({ label: each.label, value: each.value }))}
        </div>
    </div>
}

export const validateInvoiceInfo = (payload = {}) => {

    const { state , updateState } = payload;

    const { invoiceDate = "", paymentDueDate = "", paymentTerms = "", currency = "", data = [],
        sellerInfo = {}, buyerInfo = {}, remarks = "" } = state;

    if ([invoiceDate, paymentDueDate, paymentTerms, currency].includes("")) {
        showGrowlMessage({
            growlMsg: "Kindly Provide Invoice Information", growlStatus: "error",
            updateState
        });
        return true;
    }
    
    if (data && data.length === 0) {
         showGrowlMessage({
            growlMsg: "Kindly Add Atleast One Invoice Data", growlStatus: "error",
            updateState: updateState
        });
        return true;
    }

    if (_.isEmpty(sellerInfo) || _.values(sellerInfo).includes("")) {
        showGrowlMessage({
            growlMsg: "Kindly Add Seller Information", growlStatus: "error",
            updateState: updateState
        });
        return true;
    }

    if (_.isEmpty(buyerInfo) || _.values(buyerInfo).includes("")) {
        showGrowlMessage({
            growlMsg: "Kindly Add Buyer Information", growlStatus: "error",
            updateState: updateState
        });
        return true;
    }
    if (!remarks) {
        showGrowlMessage({ growlMsg: "Kindly Add Remarks", growlStatus: "error", updateState: updateState });
        return true;
    }

    return false;
}


export const autoPopulateSellerInfo = async (payload) => {

    const { updateState } = payload;

    updateState({ loader: true });

    try {
        const { status = false, result = {} } = await fetchCompany();

        if (status && !_.isEmpty(result)) {
            const { 
                companyName = "",
                address = "",
                ceoEmail = "",
                companyDomain="",
                gstNo = "",
                ceoPhone = "",
            } = result;
            updateState({
                gstNo,
                phone:ceoPhone,
                businessName: companyName,
                address,
                website:companyDomain,
                email: ceoEmail,
            });
        }
    } catch (error) {
    } finally {
        updateState({ loader: false });
    }
}

export const autoPopulateBuyerInfo = async (payload) => {

    const { state , updateState } = payload;

    const { customerEmail } = state;

    if (!customerEmail) return showGrowlMessage({ growlMsg: "Kindly Enter The Seller's Email ID", growlStatus: "error", updateState: updateState });

    updateState({ loader: true });

    try {
        const { status = false, result = {} } = await fetchCustomer({ customerEmail });
        if (status && !_.isEmpty(result)) {
            let {
                customerName = "",
                address = "",
                gstNo = "",
                customerEmail = "",
                panNumber = "",
                phone = "",
                website = ""
            } = result;
            if (!gstNo) gstNo = panNumber
            updateState({
                businessName: customerName,
                address,
                gstNo,
                panNumber,
                phone,
                website,
                email: customerEmail,
            });
        } else return showGrowlMessage({ growlMsg: "Seller Email Id Not Found", growlStatus: "success", updateState: updateState });
    } catch (error) {
    } finally {
        updateState({ loader: false });
    }
}

export const saveInvoiceInformation = async (payload = {}) => {

    const { updateState, state, isReview = false , isDraft = false, } = payload;

    let { invoiceDate = "", paymentDueDate = "", paymentTerms = "", currency = "", data = [],
        sellerInfo = {}, buyerInfo = {}, remarks = "",
        discountAmount = 0, taxAmount = 0, total = 0, subTotal = 0, dueAmount = 0, advanceAmount = 0,
        invoiceStatus = "",
    } = state;

    invoiceStatus = (dueAmount === 0 ? "PAID" : "UNPAID");

    if (isDraft) invoiceStatus = "DRAFT";

    if (validateInvoiceInfo({
        state, updateState
    })) return;

    if (isReview) {
        const { status = false, message = "" } = await createInvoice({
            invoiceDate, paymentDueDate, paymentTerms, currency, invoiceData: data,
            sellerInfo, buyerInfo, remarks,
            discountAmount, taxAmount, total, subTotal, dueAmount, advanceAmount,
            invoiceStatus,
        });
        updateState({ visible: false });
        if (status) {
            setTimeout(() => { window.location.href = `${window.location.origin}/sales`; }, 2000);
            return showGrowlMessage({ growlMsg: message, growlStatus: "success", updateState });
        }
        else showGrowlMessage({ growlMsg: "Unable to Create Invoice", growlStatus: "error", updateState });
    } else updateState({ visible: true });
}


export const updateInvoiceInfo = async (payload = {}) => {

    const { updateState, state , isConvertIntoInvoice = false } = payload;

    let { invoiceDate = "", paymentDueDate = "", paymentTerms = "", currency = "", data = [],
        sellerInfo = {}, buyerInfo = {}, remarks = "",
        discountAmount = 0, taxAmount = 0, total = 0, subTotal = 0, dueAmount = 0, advanceAmount = 0,
        invoiceNumber,invoiceStatus = ""
    } = state;

    if (validateInvoiceInfo({ state, updateState })) return;

    if (isConvertIntoInvoice) {
        invoiceStatus = (dueAmount === 0 ? "PAID" : "UNPAID")
    } else {
        invoiceStatus = (invoiceStatus === "DRAFT") ? invoiceStatus : (dueAmount === 0 ? "PAID" : "UNPAID");
    }
    const { status = false, message = "" } = await updateInvoice({
        payload: {
            invoiceDate, paymentDueDate, paymentTerms, currency, invoiceData: data,
            sellerInfo, buyerInfo, remarks,
            discountAmount, taxAmount, total, subTotal, dueAmount, advanceAmount: Number(advanceAmount),
            invoiceStatus
        },
        invoiceNumber
    });

    updateState({ visible: false });
    if (status) {
        setTimeout(() => { window.location.href = `${window.location.origin}/sales`; }, 2000);
        return showGrowlMessage({ growlMsg: message, growlStatus: "success", updateState });
    } else showGrowlMessage({ growlMsg: "Unable to Update Invoice", growlStatus: "error", updateState });
}

export const renderInvoiceSummaryUI = (payload = {}) => {

    const { state, onValueChangeAdvance, onValueChange } = payload;

    const { discountAmount, taxAmount, total, subTotal, advanceAmount = 0, dueAmount } = state;

    return <div className="flex">
        <div className="remarksUI">
            {renderRemarksUI({ state, onValueChange })}
        </div>
        <div className="invoiceSummary">
            {renderInvoiceSummaryRow("TAXABLE AMOUNT", subTotal)}
            <hr className="hrInvoice"></hr>
            {renderInvoiceSummarySmallRow("DISCOUNT (%)", discountAmount)}
            {renderInvoiceSummarySmallRow("TAX RATE (%)", taxAmount)}
            <hr className="hrInvoice"></hr>
            {renderInvoiceSummaryRow("TOTAL PAYBLE AMOUNT", total)}
            <br></br>   <br></br>
            {renderInvoiceSummarySmallInputRow("AMOUNT RECEIVED", advanceAmount, onValueChangeAdvance)}
            <hr className="hrInvoice"></hr>
            {renderInvoiceSummaryRow("REMAINING BALANCE", dueAmount)}
        </div>
    </div>
}

export const openBuyerModel = (buyerInfo , updateState) => {
    const { businessName, gstNo, address, website, phone, email } = buyerInfo || {};
    updateState({ buyerVisible: true, businessName, gstNo, address, website, phone, email })
}

export const openSellerModel = (sellerInfo , updateState) => {
    const { businessName, gstNo, address, website, phone, email } = sellerInfo || {};
    updateState({ sellerVisible: true, businessName, gstNo, address, website, phone, email })
}

export const saveSellerInfo = (state , updateState) => {
    const { businessName, gstNo, address, website, phone, email } = state || {};
    updateState({ sellerInfo: { businessName, gstNo, address, website, phone, email } , sellerVisible: false });
}

export const saveBuyerInfo = (state ,updateState) => {
    const { businessName, gstNo, address, website, phone, email  } = state || {};
    updateState({ buyerInfo: { businessName, gstNo, address, website, phone, email } , buyerVisible: false });
}


export const renderSellerModel = (payload = {}) => {

    const { state, updateState , onValueChange } = payload;

    const { sellerVisible, loader } = state;

    return <Modal id="model" show={sellerVisible} size="lg" centered effect="fadeInUp"
        onHide={() => updateState({ sellerVisible: false })}>
        <div>
            <div className="login-form extraPadding">
                <label className="refreshIcon" onClick={() => {
                    autoPopulateSellerInfo({
                        updateState
                    })
                }}><FontAwesomeIcon icon={faRedoAlt} />Auto Populate</label>
                {generateSellerInfo({
                    state,
                    onValueChange,
                    isSeller: true
                }).map(eachField => renderProductForm(eachField.inputType)(eachField))}
            </div>
            <div className="seller-btn">
                <button className="addNewProductButton" onClick={() => updateState({ sellerVisible: false })} > Cancel </button>
                <button className="addNewProductButton" onClick={() => !loader && saveSellerInfo(state, updateState)} >
                    <div> {"Save Seller Info"}</div>{loader && <div className='lds-dual-ring loaderMargin'></div>}
                </button>
            </div>
        </div>
    </Modal>
}

export const renderBuyerModel = (payload = {}) => {

    const { state, updateState , onValueChange } = payload;

    const { buyerVisible , loader , customerEmail = "" } = state;

    return <Modal id="model" show={buyerVisible} size="lg" centered effect="fadeInUp"
        onHide={() => updateState({ buyerVisible: false })}>
        <div>
            <div className="seller-search-box">
                <input type="input" value={customerEmail} onChange={onValueChange} name="customerEmail" className="searchTableInputBox" placeholder={`Search Seller By Email ID`}></input>
                <button className="button-primary searchTableButton" onClick={() => {
                    autoPopulateBuyerInfo({ state, updateState })
                }}>Auto Populate</button>
            </div>
            <div className="login-form extraPadding">
                {generateSellerInfo({
                    state,
                    onValueChange,
                    isSeller: false,
                }).map(eachField => renderProductForm(eachField.inputType)(eachField))}
            </div>

            <div className="seller-btn">
                <button className="addNewProductButton" onClick={() => updateState({ buyerVisible: false })} > Cancel </button>
                <button className="addNewProductButton" onClick={() =>  !loader && saveBuyerInfo(state ,updateState)} >
                    <div> {"Save Buyer Info"}</div>{loader && <div className='lds-dual-ring loaderMargin'></div>}
                </button>
            </div>
        </div>
    </Modal>
}

export const renderModel = (payload = {}) => {

    const { state, updateState } = payload;

    const { visible, data = [], remarks , invoiceNumber , invoiceOrderCount } = state;

    return <Modal id="model" show={visible} size="lg" centered effect="fadeInUp" onHide={() => updateState({ visible: false })}>
        <div id="invoiceCapture" className="invoicePop">
            <h3>INVOICE <span>{invoiceOrderCount ? `-${invoiceOrderCount}`: ""}</span></h3>
            {renderInvoicePreviewBacisInfo(state)}
            {renderBillingAddressInfo(state)}
            <hr className="hrInvoice"></hr>
            <br></br>
            {renderInvoicePreviewTable(data)}
            <hr className="hrInvoice"></hr>
            <div className="flex">
                <div className="flex-col billPadding" id="center">
                    <h6>NOTE / REMARKS</h6>
                    <div className="seller-value billTotalBg font14">{remarks || ""}</div>
                </div>
                <div className="flex-col billPadding" id="center">{generateInvoicePreviewPayload(state).map(each => commonLabelPreview(each))}</div>
            </div>
        </div>
        <div className="flex billPadding">
            <button className="addNewProductButton marginLeftbtn" onClick={() => invoiceNumber ? updateInvoiceInfo({
                state, updateState, isDraft:false,
            }) : saveInvoiceInformation({
                state, updateState, isReview: true , isDraft:false,
            })} > {invoiceNumber ? "Update" : "Save"}  Invoice</button>
            <button className="addNewProductButton" onClick={() => generateInvoice()} >Download Invoice</button>
        </div>
        <br></br> <br></br>
    </Modal>
}

export const renderSellerInfoLayout = (payload = {}) => {
    const { state , updateState } = payload;
    const { sellerInfo = {} , buyerInfo = {} } = state;
    const isValidSellerData = !_.values(sellerInfo).includes("") && !_.isEmpty(sellerInfo);
    const isValidBuyerData = !_.values(buyerInfo).includes("") && !_.isEmpty(buyerInfo);
    return <div className="v-card">
        <div className="flex space-between">
            <div className="seller-info-width">
                <div className="invoiceInfoHeading editLayout"> {enums.SELLER_DETAILS}<div><span onClick={() => openSellerModel(sellerInfo , updateState)} ><FontAwesomeIcon icon={faEdit} /></span></div> </div>
                <div className="flex seller-info-bg">
                    <div className="margin5Percent">
                        {isValidSellerData ? getSellerInfoData(sellerInfo).map(eachInfo => renderSellerInfo(eachInfo)) :
                            <button className="addNewProductButton" onClick={() => updateState({ sellerVisible: true })}> <FontAwesomeIcon icon={faEdit} /> Add Seller Info</button>
                        }
                    </div>
                </div>
            </div>

            <div className="seller-info-width">
                <div className="invoiceInfoHeading editLayout">{enums.BUYER_DETAILS}<div><span onClick={() => openBuyerModel(buyerInfo , updateState)}><FontAwesomeIcon icon={faEdit} /></span></div></div>
                <div className="flex seller-info-bg">
                    <div className="margin5Percent">
                        {isValidBuyerData ? getSellerInfoData(buyerInfo).map(eachInfo => renderSellerInfo(eachInfo)) :
                        <button className="addNewProductButton" onClick={() => updateState({ buyerVisible: true })}> <FontAwesomeIcon icon={faEdit} /> Add Buyer Info</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const renderBasicInvoiceDetails = (payload = {}) => {
    const { state, onValueDateChange } = payload;
    const { currencyList , currency , paymentTerms , paymentList , invoiceDate = new Date() , paymentDueDate = new Date() } = state;
    return <div>
        <div className="invoiceInfoHeading">{enums.INVOICE_INFORMATION} *</div>
        <div className="invoiceInfo">
            <AutocompleteUI name="currency" value={currency} options={currencyList} label="Currency" onChange={onValueDateChange} />
            <MaterialUIPickers name="invoiceDate" value={invoiceDate} label={"Invoice Date"} value={new Date(invoiceDate)} onChange={onValueDateChange} />
            <MaterialUIPickers name="paymentDueDate" value={paymentDueDate} label={"Payment Due Date"} value={new Date(paymentDueDate)} onChange={onValueDateChange} />
            <AutocompleteUI name="paymentTerms" value={paymentTerms} options={paymentList} label="Payment Terms" onChange={onValueDateChange} />
        </div>
    </div>
}

export const renderSearchProductUI = (payload) => {
    const { state, onValueDateChange } = payload;
    const { searchTerm, paymentList } = state;
    return <div className="invoiceInfo">
        <AutocompleteUI name="searchTerm" value={searchTerm} options={paymentList} label="Search Product By Name" onChange={onValueDateChange} />
    </div>
}

export const getCSScolorBadge = (invoiceStatus) => {
    const badge = {
        "DRAFT": "draft-color invoice-badge",
        "PAID": "paid-color invoice-badge",
        "UNPAID": "unpaid-color invoice-badge",
    }
    return badge[invoiceStatus] || ""
}
export const renderNewInvoiceUI = (payload = {}) => {
    const { invoiceStatus } = payload;
    return <div className="addNewInvoiceBtnUI">
        {renderQuotation(payload)}
        <button className="addNewProductButton" onClick={() => window.location.href = `${window.location.origin}/invoice`}> <FontAwesomeIcon icon={faEdit} /> Create New Invoice</button>
        <div className="align-center-text">{invoiceStatus ? <p className={`${getCSScolorBadge(invoiceStatus)}`} > {invoiceStatus}</p> : null}</div>
    </div>
}
export const renderQuotation = (payload = {}) => {
    const { invoiceNumber, state, updateState } = payload;
    return !invoiceNumber ? <button className="draftButton" onClick={() => {
        saveInvoiceInformation({ state, updateState, isReview: true, isDraft: true })
    }}> Create Quotation (Draft)</button> : null
}

export const renderConvertQuotation = (payload = {}) => {
    const { invoiceNumber, state, updateState, invoiceStatus } = payload;
    return invoiceNumber && invoiceStatus === "DRAFT" ? <button className="draftButton" onClick={() => {
        updateInvoiceInfo({ state, updateState, isReview: true, isDraft: false , isConvertIntoInvoice : true })
    }}> Convert to Invoice</button> : null
}

export const renderReviewButton = (payload = {}) => {
    const { state, updateState, } = payload;
    return <Fragment>
        <hr className="hrInvoice"></hr>
        <br></br>
        <div>
            <button className="addNewProductButton" onClick={() => {
                saveInvoiceInformation({ state, updateState, isReview: false , isDraft: false })
            }} >Review Invoice</button>
        </div>
        <br></br> <br></br>
    </Fragment>
}