import { growl } from '@crystallize/react-growl';

import _ from 'lodash'

const validator = require('validator');

const ORGANIZATION_ID = "UAUANSAYV";

export const showGrowMsg = async (settings) => {

  let { message, type = "info", sticky = false, timeout = 6000 } = settings;

  await growl({ message, type, sticky, timeout });
}

export const getOrganizationID = () => ORGANIZATION_ID;

// This function is used to check whether the platform is being used for Urban Us (for specific changes);
export const isUrbanUsInstance = () => ORGANIZATION_ID === "UAUANSAYV";

export const logOut = async () => {
  localStorage.clear();
  window.location.href = `${window.location.origin}/login`;
}

export const validatingEmptyFormFields = (arrayValues) => {
  return arrayValues.every(validatingEmptyFields);
}

export const validatingEmptyFields = (currentValue) => currentValue === "";

export const validatingFormFields = (arrayValues) => {
  return arrayValues.every(validatingEachFields);
}

export const validatingEachFields = (currentValue) => currentValue !== "";

export const downloadLink = (URL, fileName) => {
  let element = document.createElement("a");
  element.setAttribute(
    "href",
    URL
  );
  
  element.setAttribute(
    "target",
    "_blank"
  );

  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


export const DDDataMappingAdapter = (dataMapping) => {
  const result = { ...dataMapping }
  Object.keys(result).map((category) => {
    let subCategory = {};
    result[category].map((eachCategory) =>
      subCategory[eachCategory.category] = eachCategory.mapping
    );
    return result[category] = subCategory;
  })
  return result;
}

export const formatToCurrency = (num) => {
  let number = parseInt(num);
  if (isNaN(number)) return "$0"
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'USD'
  }).format(number);
}

//.replace(/^(\D+)/, '$1 '); => this if we need space blw $ and number.


export const checkFileTypeValidation = (expectedFile, actualFile) => {
  if (expectedFile) return actualFile && expectedFile.includes(actualFile);
  return false;
}

export const getFileSizeInMb = (file) => {

  const size = file && file.size;

  return (size / 1024) / 1000;
}

export const getTabNameArray = sectionTabName => (`${sectionTabName}`.split('').splice(0, sectionTabName.length - 1));

export const getTabNumber = sectionTabName => (`${sectionTabName}`.split('').splice(-2).join(''));

export const spliceTableEntry = (list, index, count) => (list && list.length && list.slice(index, index + count));

export const calculateTableIndex = (index, page, pageSize) => ((page - 1) * pageSize + index);

export const pageCountFormula = (totalNumberOfCount, maxCountPerPage) => Math.ceil(totalNumberOfCount / maxCountPerPage);

export const isUrlValid = (userInput) => (validator.isURL(userInput))

export const formatInputParam = (value) => {
    value = `${value}`.split(',').join('');
		if (value && `${value}`.includes('.')) return +`${value}`.split('.')[0];
		if (value && +value < 0) return +value * -1;
		if (value === '') return '';
		return +value;
}

export const getPreviousFinancialDataSpecificValue = (metaData = {}, columnName = '', state={}) => {
  const { monthYearIndex = 0, contractNotesHeadingIndex = 0 } = metaData
  const { previousFinancialData = [] } = state
  const value =  _.get(previousFinancialData, `${monthYearIndex}.${contractNotesHeadingIndex}.${columnName}`, "")
  return value
}

export const zeroInEmptyFields = (state) => {
  const { previousFinancialData } = state
  for ( let monthIndex=0; monthIndex < previousFinancialData.length; monthIndex++ ) {
      for ( let recordIndex=0; recordIndex < previousFinancialData[monthIndex].length; recordIndex++ ){
          let keyArray = Object.keys(previousFinancialData[monthIndex][recordIndex])
          for (let keyIndex = 0 ; keyIndex < keyArray.length ; keyIndex++){
              if (previousFinancialData[monthIndex][recordIndex][keyArray[keyIndex]] === ""){
                  previousFinancialData[monthIndex][recordIndex][keyArray[keyIndex]] = "0"
              }
          }
      }
  }
  return previousFinancialData;
}