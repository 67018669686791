import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import React from 'react'; 
import '../TextInput/style.css'
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

class Input extends Component {
    state = {}

    componentDidMount() {
        let { type } = this.props;

        // Set Flags for Password Visibility in State
        var isPassword = type === 'password';
        var showPassword = false;
        this.setState({ type, showPassword, isPassword })
    }

    onChange = (event) => {
        this.props.onValueChange(event);
    }

    togglePasswordVisibility = () => {
        // Toggle the Visibility of Password by altering the field "type" in State
        let {showPassword, isPassword, type} = this.state;
        showPassword = showPassword ? false : true;
        type = isPassword ? (showPassword ? 'text' : 'password') : type;
        this.setState({showPassword, type});
    }

    redirectToForgotPassword = () => {
        this.props.history.push("/forgot-password");
    }

    renderLabel = () => {
        const { showForgotPassword } = this.props;
        return (<> <div className="login-space-between">
            {this.renderLabelText(this.props)}
            {showForgotPassword && <div onClick={this.redirectToForgotPassword} className="forgot-password-text">Forgot Password ?</div>}
        </div></>);
    }

    renderLabelText = (props) => {
        const {
            label,
            inputLabelStyleClass,
            isImportant,
        } = props;
        return label ? <label className={inputLabelStyleClass ?
            inputLabelStyleClass : 'default-label-style'} >{label}{this.renderStarSymbol(isImportant)}</label>
            : null
    }
    renderStarSymbol = (isImportant) => (isImportant ? <span className='impAsterisk'>*</span> : null)

    renderInputField = () => {
        let { name, placeholder, inputStyleClass, onChange, value='', required = null, disabled=null } = this.props;
        let { isPassword, type, showPassword } = this.state;

        // Select Icon Based on Password Visibility
        let icon = showPassword ? faEyeSlash : faEye;

        return (
            <><input
                className={inputStyleClass ? inputStyleClass : 'default-input-style'}
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={(e) => { onChange(e) }}
                value={value}
                required={required}
                disabled={disabled}
            />
            { /** Render the Toggle Visibility Icon */ }
            { isPassword && <span className="showPassword" onClick={this.togglePasswordVisibility}><FontAwesomeIcon icon={icon} /></span>}</>
        )
    }

    render() {
        return (
            <div className="registration-input-wrapper">
                {this.renderLabel()}
                {this.renderInputField()}
            </div>
        )
    }
}
export default compose(withRouter, connect())(Input);