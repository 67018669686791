import { mimeTypes } from "../../../../utils/enums";

const { stateList } = require("../../../../Services/mockData/companies");


const _ = require("lodash");

export const signUpPayload = (state) => {
  const { firstName, lastName, email, password, passwordConfirm, error, phone } = state;
  return [
    {
      name: "firstName", type: "string", value: firstName, placeholder: "Enter First Name", label: "First Name", errorState: error.firstName,
      errorMsg: "Enter Your Valid First Name",important: true
    },
    {
      name: "lastName", type: "string", value: lastName, placeholder: "Enter Last Name", label: "Last Name", errorState: error.lastName,
      errorMsg: "Enter Your Valid Last Name" , important: true
    },
    {
      name: "email", type: "email", value: email, placeholder: "Enter Email",
      label: "Email", errorState: error.email, important: true,
      errorMsg: "Enter Valid Email ID"
    },
    {
      name: "phone", type: "phone", value: phone, placeholder: "Enter Phone Number",
      label: "Phone", errorState: error.phone,
      errorMsg: "Enter Valid Phone Number", important: true
    },
    {
      name: "password", type: "password", value: password, placeholder: "Enter Password", label: "Password", errorState: error.password,
      errorMsg: "Minimum 8 characters", important: true
    },
    {
      name: "passwordConfirm", type: "password", value: passwordConfirm, placeholder: "Confirm Password", label: "Confirm Password", errorState: error.passwordMismatch,
      errorMsg: "The Passwords Should Match", important: true
    },
  ]
}


export const companyDetailsPayload = (state) => {
  const { companyName, companyDomain, error , gstNo } = state;
  return [
    {
      label: "Company Name", type: "string", name: "companyName", value: companyName, placeholder: "Enter Company Name", errorState: error.companyNameError,
      errorMsg: "Enter Your Valid Company Name" , important: true
    },
    {
      label: "Website / Company Domain", type: "string", name: "companyDomain", value: companyDomain, placeholder: "Enter Website", errorState: error.domainError,
      errorMsg: "Enter Your Valid Company Domain", important: true
    },
    {
      label: "Gst No", type: "string", name: "gstNo", value: gstNo, placeholder: "Enter Gst No", errorState: error.gstNoError,
      errorMsg: "Enter Your Valid Gst No", important: true
    },
  ]
}

export const companyMetaDetailsPayload = (state) => {
  const { overview, address, error } = state;
  return [
    {
      label: "Describe your business. Mention how big you are, key clients, size of business etc", type: "textarea", name: "overview", value: overview, placeholder: "Enter Overview", errorState: error.overviewError,
      errorMsg: "Overview Characters should be a Minimum of 25 and a Maximum of 250 required", important: false
    },
    {
      label: "Business Location", type: "textarea", name: "address", value: address, placeholder: "Enter Example Customer", errorState: error.addressError,
      errorMsg: "Business Location Characters should be a Minimum of 25 and a Maximum of 250 required", important: true
    },
  ]
}

export const sectorPayload = (state) => {

  const { sector, subSector, error, sectorList, subSectorList } = state;

  return [{
    label: "Sector", type: "dropdown", name: "sector", value: sector,
    placeholder: "Select Sector", errorState: error.sectorError,
    errorMsg: "Select Your Sector", values: sectorList, important: true
  }, {
    label: "Sub-Sector", type: "dropdown", name: "subSector",
    value: subSector,
    placeholder: "Select Sub Sector", errorState: error.subSectorError,
    errorMsg: "Select Your Sub Sector", values: subSectorList,important: true
  }];
}

export const statePayload = (payload) => {
  const { state, city, error, cityList} = payload;
  return [{
    label: "State", type: "dropdown", name: "state", value: state,
    placeholder: "Select State", errorState: error.stateError, important: false,
    errorMsg: "Select State", values: stateList
  }, {
    label: "City", type: "autoComplete", name: "city",
    value: city,
    placeholder: "Select City", errorState: error.cityError,
    errorMsg: "Select Your City", values: cityList, important: false
  }];
}

export const countryPayload = (payload) => {
  const { country, error } = payload;
  return {
    label: "Country", type: "dropdown", name: "country", value: country,
    placeholder: "Select Country", errorState: error.countryError,
    errorMsg: "Select Country", values: [], important: true
  }
}

export const ceoDetailsPayload = (state) => { 
  const { ceoName, ceoEmail, error , pinCode , ceoPhone } = state;
  return [
    {
      label: "Postal Code / Zip Code", type: "string", name: "pinCode", value: pinCode, placeholder: "Enter Postal Code / Zip Code", errorState: error.pinCodeError,
      errorMsg: "Enter Postal Code / Zip Code", important: true
    },
    {
      label: "Name", type: "string", name: "ceoName", value: ceoName, placeholder: "Enter Name", errorState: error.ceoNameError,
      errorMsg: "Enter Your Valid Name", important: true
    },
    {
      label: "Phone", type: "string", name: "ceoPhone", value: ceoPhone, placeholder: "Enter Name", errorState: error.ceoPhoneError,
      errorMsg: "Enter Your Valid Phone", important: true
    },
    {
      label: "Email", type: "string", name: "ceoEmail", value: ceoEmail, placeholder: "Enter Email", errorState: error.ceoEmailError,
      errorMsg: "Enter Your Valid Email", important: true
    },
  ]
}

export const logoDetailsPayload = (state) => {
  const { pitchDeck, companyLogo, error } = state;
  return [

    {
      label: "Company Logo", type: "string", name: "companyLogo", value: companyLogo, placeholder: "Enter Company Logo", errorState: error.logoError,
      fileType: mimeTypes.images,errorMsg: "Upload Your Company Logo", important: false
    },
    {
      label: "Other Documents", type: "string", name: "pitchDeck", value: pitchDeck, placeholder: "EnterOther Documents", errorState: error.pitchDeckError, fileType: mimeTypes.pdf,
      errorMsg: "Upload Your Other Documents" , important: false
    },
  ]
}

export const employeeCountPayload = () => {
  return ["1-10", "11 - 50", "51 - 200", "201-500", "500+"]
}

export const teamSizePayload = (employeeCount) => {
  return {
    errorMsg: "Please Select The Number of Employees",
    errorState: false,
    important: true,
    name: "employeeCount",
    placeholder: "Select Team Size",
    type: "dropdown",
    label: "Team Size",
    value: employeeCount,
    values: employeeCountPayload(),
  }
}


export const getBusinessInfo = (state) => {
  const { businessType , categories } = state;
  return [
    {
      errorMsg: "Please Select The Business Type",
      errorState: false,
      important: true,
      name: "businessType",
      placeholder: "Select Business Type",
      type: "dropdown",
      label: "Business Type",
      value: businessType,
      values: [
        "Individual / Freelancer",
        "Offline Shop / Trader / Broker",
        "Online Tool / SaaS",
        "Others"
      ],
    },
    {
      errorMsg: "Please Select The Categories",
      errorState: false,
      important: true,
      name: "categories",
      placeholder: "Select Categories",
      type: "dropdown",
      label: "Categories",
      value: categories,
      values: [
        "Health",
        "Blog",
        "Retail Store",
        "Online Tool",
        "Others",
        "Web Development"
      ],
    }
  ]
}

export const formatNameFromResult = (country) => _.map(country, 'name');

