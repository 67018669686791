import { LOCALES } from '../locals'
export default {
    [LOCALES.ENGLISH]: {
        assignDealLead: 'Assign Deal Lead',
        upNext: 'Up Next',
        lastTaskCompleted: 'Last Task Completed',
        pitchDeck: 'Open Application',
        outputSummary: 'Output Summary',
        partnersApproval: 'Partners Approval',
        opportunityChecklist: 'Opportunity Checklist',
        uuCompanyScorecard: 'UU Company Scorecard',
        dueDiligence: 'Due Diligence',
        dealLead: 'Deal Lead',
        NDAStatus: 'NDA Status',
        UploadDocument: 'Upload document',
        signed: 'Signed',
        overallScore: 'Overall Score',
        scores: 'Scores',
        showAllScores: 'Show All Scores',
        logout: 'Logout',
        openApplications: 'Open Applications',
        actyvApplications: 'Actyv Applications',
        rejectedApplications: 'Rejected Applications',
        futureReference: 'Future Reference Applications',
        portfolio: 'Portfolio',
        poweredBy: 'Powered By',
        GoNoGO: 'Proceed / Don\'t Proceed',
        DDAnalysisReport: 'DD Analysis Report by Actyv',
        DDDocumentStartup: 'DD Document by Startup',
        DueDiligenceBorrowerRating: 'Borrower Rating',
        dueDiligenceAssetValueAnalysis: 'Asset Value Analysis',
        UUCompanyScoring: 'UU Company Scoring',
        goNoGoSection: 'Proceed / Don\'t Proceed',
        selfUUCompanyScoring: 'self UU Company Scoring',
        DealLeadUUCompanyScoring: 'Deal Lead UU Company Scoring',
        assignDealLeadTab: "Assign Deal Lead Tab",
        NDADocument: "NDA Document",
        startupUUCompanyScoring: "Company Scoring",
        UUCompanyScoringPartner : "UU Company Scoring Partner",
        noEntriesFound: "No Entries Found",
        theDDDocuments: "The Due Diligence Documents",
        theApplication: "The Application",
        ContractCFSchedule: "Contract CF Schedule",
        Archive: "Archive",
        saveForFuture: "Save For Future",
        areYouSure: "Are you sure that you want to",
        thisDeal: "this deal",
        cancel: "Cancel",
        application: "Application",
        yesProceed: "Yes, Proceed",
        subDecision: "Sub-decision:",
        confirm: "Confirm",
        submitScoringWithoutValidation: "It appears that all the Questions have not been scored yet. Are you sure that you want to submit Scoring?",
        bypassScoring: "Are you sure that you want to proceed without going through the scoring process?",
        comeback: "(You can always come back to this step to make changes.)",
        submitScore: "Submit Company Score",
        submitScoreLater: "Complete Scoring Later"
    }
}