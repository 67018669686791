import React, { Component } from 'react';
import './styles.css'
import Button from '../../Components/FormComponents/Button'
import { getOrganizationID } from '../../utils/utils';
import { storeToken, storeUserData } from '../../utils/reduxUtils';
import { loginPayload } from './formPayload';
import { isInvalid } from '../../utils/validations';
import { loginUser } from '../../utils/endPoints';
import Loader from '../../utils/loader';
import { withRouter } from 'react-router-dom';
import { showGrowlMessage , renderGrowlUI } from '../../Services/index';
import { fetchTokenFromReCaptchaV3 } from '../../utils/recaptchav3';
import {renderInputFields , renderCheckBox  } from '../../Components/InputComponents/index';
import { connect } from 'react-redux';
import { compose } from 'redux';
const { enums } = require('./enums');

class Login extends Component {

    state = {
        email: "",
        password: "",
        role: "",
        terms: true,
        loader: false
    }
  
    updateState = (state) =>  this.setState(state);

    handleLogin = async () => {

        const { email, password, terms, organizationId } = this.state;

        let emailError = isInvalid(enums.EMAIL, email);

        let passwordError = isInvalid(enums.PASSWORD, password);

        this.setState({ error: { name: false, email: emailError, password: passwordError } });

        if (emailError || passwordError) return showGrowlMessage({ growlMsg: enums.EMAIL_VALIDATION_MESSAGE, growlStatus: enums.ERROR, updateState: this.updateState });

        if (terms) return showGrowlMessage({ growlMsg: enums.TERMS_AND_CONDITIONS_ERROR, growlStatus: enums.ERROR, updateState: this.updateState });

        this.setState({ loader: true });

        const payload = {
            email,
            password,
            organizationId,
            googleToken: await fetchTokenFromReCaptchaV3()
          };

        this.loginUserFlow(payload);
    }

    loginUserFlow = async (payload) => {

        try {
            const response = await loginUser(payload);

            this.setState({ loader: false })

            if (response.status === enums.SUCCESS) {
                const { token, result } = response.data;
                storeToken(this.props, token);
                storeUserData(this.props, result);
                this.setState({ loader: false });
                return this.props.history.replace("/sales");
            }
            showGrowlMessage({ growlMsg: enums.INVALID_LOGIN_ERROR, growlStatus: enums.ERROR, updateState: this.updateState });
        } catch (error) {
            this.setState({ loader: false });
        }
    }

    onValueChange = (event) => {
        const { name, value } = event.target;
        if (name === enums.TERMS) this.setState({ terms: !this.state.terms });
        else this.setState({ [name]: value });
    }
    componentDidMount() {
        this.setState({  organizationId: getOrganizationID() });
    }

    renderHeaderPart = () => {
        return <div className='login-text-container'>
            <p className='text-login-to-account' >{enums.LOGIN_ACCOUNT}</p>
        </div>
    }

    redirectToForgotPassword = () => {
        this.props.history.push("/forgot-password");
    }
    

    renderLoginLogo = () => {
        return (<div className="flexLoginHalfWidthBox left">
            <img className='login-image-logo' src={require('../../assets/images/loginImage.png').default} />
        </div>)
    }

    renderSignInButton = () => {
        const { loader } = this.state;
        return (<div className='sign-in-button-wrapper'>
            {loader ? <Loader /> : <Button name={enums.SIGN_IN} onPress={() => { this.handleLogin() }} />}
        </div>)
    }
    renderLoginUI = () => {

        const { terms } = this.state;

        const loginForm = loginPayload(this.state);

        return <div className="flexLoginHalfWidthBox right">
            <div className="login-form-second-layout">
                <div className='login-form-layout'>
                    <p className='text-login-Welcome-header'>{enums.WELCOME_MESSAGE}</p>
                    {this.renderHeaderPart()}
                    {loginForm.map((field) => renderInputFields(field , this.onValueChange))}
                    {renderCheckBox({ label: enums.TERMS_AND_CONDITIONS, type: "checkbox", name: enums.TERMS, value: terms } , this.onValueChange)}
                    {this.renderSignInButton()}
                    <div className="common-field-margin-top">
                        <p className="css-1h15lvr">Not registered yet? <a href="/sign-up"><span className="css-evbtna">Create an Account</span></a></p>
                    </div>
                </div>
            </div>
        </div>
    }
    render() {

        return (
            <div>
                {renderGrowlUI(this.state, this.updateState)}
                <div className="flexLoginBox">
                    {this.renderLoginLogo()}
                    {this.renderLoginUI()}
                </div>
            </div>
        );
    }
}

export default compose(withRouter, connect())(Login);