import React from 'react';

import { renderInputFields } from '../../Components/InputComponents/index';

import { renderDropDown } from '../Registration/Components/service/index';

export const renderDropDownUI = (field) => {
    const { name, value, label, placeholder, values , important , onValueChange } = field;
    return <div className="common-field-margin-top">
        {renderDropDown({
            name: name, type: "text", value: value, label: label, placeholder: placeholder,
            values: values, onValueChange: onValueChange , important,
        })}
    </div>
}

export const renderInputUI = (field) => {
    const { name, type, value, label, placeholder, important , onValueChange } = field;
    return renderInputFields({ name: name, type: type, value: value, label: label, placeholder, important }, onValueChange)
}

export const renderProductForm = (inputType) => {
    const inputConfig = {
        ["input"]: renderInputUI,
        ["dropdown"]: renderDropDownUI
    }
    return inputConfig[inputType] || renderInputUI;
}