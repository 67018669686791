
const { regexExp } = require('../utils/validationRegex/index');

const { ALPHA_WITH_SPACE, MOBILE_NUMBER, DOMAIN, ALPHA_NUMERIC_WITH_SPACE_REG_EXP } = regexExp;

const validator = require('validator');

const MINIMUM_CHARACTER_LENGTH = 25;

const MAX_CHARACTER_LENGTH = 250;

const isInvalid = (field, value) => {

  switch (field) {

    case 'name':  return (!(checkIfEmptyString(value) && ALPHA_WITH_SPACE.test(value)));

    case 'companyName': return (!(checkIfEmptyString(value) && ALPHA_NUMERIC_WITH_SPACE_REG_EXP.test(value)));

    case 'password': return (!(checkIfEmptyString(value) && String(value).trim().length >= 8))

    case 'email': return (!(checkIfEmptyString(value) && validator.isEmail(`${value}`)));

    case 'phone': return !(MOBILE_NUMBER.test(value));

    case 'domain': return (!(checkIfEmptyString(value) && DOMAIN.test(value)));

    case "empty" : return !(checkIfEmptyString(value));

    case "min_max_character": return !(checkIfEmptyString(value) && characterValidation(value, MAX_CHARACTER_LENGTH, MINIMUM_CHARACTER_LENGTH));

    default: return false
  }
}
const checkIfEmptyString = (value) => { return value && String(value).trim() !== '' };

const characterValidation = (textValue, maxCount, minCount) => {
  return textValue && textValue.length <= maxCount && textValue.length >= minCount;
}

module.exports = { isInvalid, checkIfEmptyString }