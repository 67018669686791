import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getCSScolorBadge } from "../../Invoices/method"

import { faEdit } from '@fortawesome/free-solid-svg-icons';

import moment from "moment";

export const formatIndianNumberFormat = (value = 0) => {
    return `${Intl.NumberFormat('en-IN').format(value)} ₹ ` || `0 ₹ `;
}

export const generateTablePayload = ({ onEdit }) => {
    return [
        {
            name: 'Invoice Date',
            selector: row => moment(row.invoiceDate).format('DD-MM-YYYY'),
            sortable: false,
        },
        {
            name: 'Invoice Order',
            selector: row => <p><a className="editTag">{row.invoiceOrderCount}</a></p>,
            sortable: true,
        },
        {
            name: 'Invoice No',
            selector: row => <p><a className="editTag">{row.invoiceNumber}</a></p>,
            sortable: true,
        },
        {
            name: 'Buyer Name',
            selector: row => row.buyerInfo.businessName,
            sortable: false,
        },
        {
            name: 'Invoice Value',
            selector: row => formatIndianNumberFormat(row.total),
            sortable: false,
        },
        {
            name: 'Balance',
            selector: row => formatIndianNumberFormat(row.dueAmount),
            sortable: false,
        },
        {
            name: 'Status',
            selector: row => <p className={getCSScolorBadge(row.invoiceStatus)}>{row.invoiceStatus}</p>,
            sortable: false,
        },
        {
            name: 'Due Date',
            selector: row => moment(row.paymentDueDate).format('DD-MM-YYYY'),
            sortable: false,
        },
        {
            name: 'Edit',
            selector: row => <p><a className="editTag" onClick={() => onEdit(row)}><FontAwesomeIcon icon={faEdit} /></a></p>,
            sortable: false,
        },
    ]
};

export const conditionalRowStyles = [
    // {
    //     when: row => row.invoiceStatus === "DRAFT",
    //     style: {
    //         backgroundColor: '#ff9f43',
    //         color: '#fff',
    //         fontSize: "14px",
    //         fontWeight: "600"
    //     },
    // },
    // {
    //     when: row => row.invoiceStatus === "PAID",
    //     style: {
    //         backgroundColor: '#28c76f',
    //         color: '#fff',
    //         fontSize: "14px",
    //         fontWeight: "600"
    //     },
    // },
    // {
    //     when: row => row.invoiceStatus === "UNPAID",
    //     style: {
    //         backgroundColor: '#ea5455',
    //         color: '#fff',
    //         fontSize: "14px",
    //         fontWeight: "600"
    //     },
    // }
];

export const getFilterPayload = (invoiceStatus) => {
    return [
        { name: "invoiceStatus", value: "", label: "ALL", inputValue: invoiceStatus },
        // { name: "invoiceStatus", value: "DRAFT", label: "DRAFT", inputValue: invoiceStatus },
        { name: "invoiceStatus", value: "PAID", label: "PAID", inputValue: invoiceStatus },
        { name: "invoiceStatus", value: "UNPAID", label: "UNPAID", inputValue: invoiceStatus }
    ]
}