import React from 'react';

import DataTable ,{ createTheme } from 'react-data-table-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';

createTheme('solarized', {
    text: {
        primary: '#293961',
        secondary: '#293961',
    },
    background: {
        default: '#FFFFFF',
    },
    // context: {
    //   background: '#cb4b16',
    //   text: '#FFFFFF',
    // },
    divider: {
        default: '#dfdfdf',
    },
    action: {
        button: 'green',
        hover: 'green',
        disabled: 'green',
    },
}, 'light');

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

// https://react-data-table-component.netlify.app/?path=/docs/api-custom-conditional-formatting--page

export default function DataTableUI(props) {

    const { searchBy = "Products" } = props; 

    const customFilterComponent = <div>
        <label className="refreshIcon" onClick={props.refreshFilter}><FontAwesomeIcon icon={faRedoAlt} /> Refresh</label>
        <input type="input" onChange={props.onFilterValueChange} name="searchTerm" className="searchTableInputBox" placeholder={`Search ${searchBy} By Name`}></input>
        <button className="button-primary searchTableButton" onClick={props.applyFilter}> Search </button>
    </div>

    return (
        <DataTable
            theme="solarized"
            title={props.title}
            conditionalRowStyles={props.conditionalRowStyles}
            columns={props.columns}
            data={props.data}
            selectableRows={props.selectableRows}
            expandableRows={props.expandableRows}
            expandableRowsComponent={ExpandedComponent}
            subHeader={props.subHeader}
            subHeaderComponent={customFilterComponent}
            pagination={props.pagination}
            reorder
			paginationServer
            paginationTotalRows={props.totalRows}
            onChangePage={props.onChangePage}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            fixedHeader={false}
            fixedHeaderScrollHeight="300px"
            progressPending={props.loading}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={props.onRowClicked}
        />
    )
}
