import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function SnackBars(props) {

  const [state] = React.useState({
    open: props.showBar ? props.showBar : false,
    status: props.status ? props.status : 'success',
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  return (
    <div>
      <Snackbar
        open={props.showBar}
        autoHideDuration={6000}
        onClose={props.handleClose}
        anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={props.handleClose} severity={props.status}>
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
