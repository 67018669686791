const enums = {
    VALID_DOCUMENT_ERROR: "Please Upload a Valid Document",
    FILE_SIZE_ERROR : "File Size Is Too Large, Compress and Upload",
    ERROR: "error"
}

const formSteps = { User: "User", Company: "Company", CEO: "CEO", Completion: "Completion" };

const nextStep = {
    "User": "Company",
    "Company": "CEO",
    "CEO": "Completion"
}

const prevStep = {
    "Company": "User",
    "CEO": "Company"
}


const nextStepForPartner = { "Company": "CEO" }

const prevStepForPartner = { "CEO": "Company" }

const formStepsForPartner = { Company: "Company", CEO: "CEO", };

const reUploadConfirmMessage = "This action will replace your currently uploaded file, are you sure you want to continue?"

module.exports = { enums, formSteps, nextStep, prevStep, reUploadConfirmMessage , nextStepForPartner ,prevStepForPartner , formStepsForPartner }