import React, { Component } from 'react';
import '../Button/styles.css'

export default class componentName extends Component {
    render() {
        let { name, onPress , className} = this.props
        return (
            <button onClick={() => onPress()} className={className ? className : 'button-primary'} >{name}</button>
        );
    }
}
