import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Services/Themes/index.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GrowlScene } from '@crystallize/react-growl';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from "./redux/store";
const { store, persister } = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <GrowlScene />
      <PersistGate loading={null} persistor={persister}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
