import React from 'react';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export const generateTablePayload = ({ onDelete, onTableValueChange }) => {
    return [
        {
            name: 'Item',
            selector: (row, index) => <div>
                <input className="default-input-style" onChange={(e) => { onTableValueChange(e, index) }} type="string" name={`item`} value={row.item}></input>
            </div>,
            sortable: true,
        },
        {
            name: 'Description',
            selector: (row, index) => <div>
                <input className="default-input-style" onChange={(e) => { onTableValueChange(e, index) }} type="string" name={`description`} value={row.description}></input>
            </div>,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: (row, index) => <div>
                <input className="default-input-style" onChange={(e) => { onTableValueChange(e, index) }} name={`quantity`} type="number" value={row.quantity}></input>
            </div>,
            sortable: false,
        },
        {
            name: 'Unit',
            selector: (row, index) => <div className="fullWidth">
                <select className="default-input-style fullWidth"
                    onChange={(e) => { onTableValueChange(e, index) }}
                    name={`unit`} value={row.unit}>
                    <option value="PCS">PCS</option>
                    <option value="KGS">KGS</option>
                    <option value="LITRE">LITRE</option>
                    <option value="METER">METER</option>
                    <option value="SETS">SET</option>
                    <option value="GRAMS">GRAMS</option>
                    <option value="BOX">BOX</option>
                </select>
            </div>,
            sortable: false,
        },
        {
            name: 'Price',
            selector: (row, index) => <div>
                <input className="default-input-style" onChange={(e) => { onTableValueChange(e, index) }} name={`price`} type="number" value={row.price}></input>
            </div>,
            sortable: false,
        },
        {
            name: 'Discount(%)',
            selector: (row, index) => <div>
                <input className="default-input-style" onChange={(e) => { onTableValueChange(e, index) }} name={`discountRate`} type="number" value={row.discountRate}></input>
            </div>,
            sortable: false,
        },
        {
            name: 'Tax Rate (%)',
            selector: (row, index) => <div className="fullWidth">
                <select className="default-input-style fullWidth"
                    onChange={(e) => { onTableValueChange(e, index) }}
                    name={`taxRate`} value={row.taxRate}>
                    <option value="28">28%</option>
                    <option value="18">18%</option>
                    <option value="12">12%</option>
                    <option value="5">5%</option>
                    <option value="0">0%</option>
                </select>
            </div>,
            sortable: false,
        },
        {
            name: 'Total',
            selector: (row, index) => <div>
                <input className="default-input-style" disabled type="number" name={`price`} value={row.total}></input>
            </div>,
            sortable: false,
        },
        {
            name: 'Delete',
            selector: (row, index) => <p><a className="deleteTag" onClick={() => onDelete(row, index)}><FontAwesomeIcon icon={faTrashAlt} /></a></p>,
            sortable: false,
        },
    ]
};


export const generateInvoicePreviewPayload = (state = {}) => {
    const { subTotal, taxAmount, discountAmount, advanceAmount, dueAmount, total } = state;
    return [
        { label: "TAXABLE AMOUNT", value: subTotal },
        { label: "TAX AMOUNT", value: taxAmount },
        { label: "DISCOUNT AMOUNT", value: discountAmount, },
        { label: "TOTAL PAYBLE AMOUNT", value: total, isBackground: true },
        { label: "AMOUNT RECEIVED", value: advanceAmount },
        { label: "REMAINING BALANCE", value: dueAmount, isBackground: true }
    ]
}

export const generateSellerInfo = (payload) => {

    const { state, isSeller = false , onValueChange } = payload;

    const { businessName, gstNo, address, website, phone, email } = state;

    if (isSeller) {
        return [
            { name: "businessName", inputType: "input", type: "text", value: businessName, label: "Business Name", placeholder: "Enter Business Name", onValueChange:onValueChange },
            { name: "gstNo", inputType: "input", type: "text", value: gstNo, label: "GstNo", placeholder: "Enter Gst No",  onValueChange:onValueChange },
            { name: "email", important: true, type: "text", value: email, label: "Seller Email", placeholder: "Enter Seller Email",  onValueChange:onValueChange, inputType: "input" },
            { name: "address", type: "text", value: address, label: "Address", placeholder: "Enter Address", inputType: "input",  onValueChange:onValueChange },
            { name: "phone", type: "number", value: phone, label: "Phone", placeholder: "Enter Phone Number", inputType: "input",  onValueChange:onValueChange },
            { name: "website", type: "text", value: website, label: "Website", placeholder: "Enter Website",  onValueChange:onValueChange, important: true, inputType: "input" }
        ]
    }
    return [
        { name: "businessName", inputType: "input", type: "text", value: businessName, label: "Business Name", placeholder: "Enter Business Name", onValueChange:onValueChange },
        { name: "gstNo", inputType: "input", type: "text", value: gstNo, label: "GstNo", placeholder: "Enter Gst No", onValueChange:onValueChange },
        { name: "email", important: true, type: "text", value: email, label: "Seller Email", placeholder: "Enter Seller Email", onValueChange:onValueChange, inputType: "input" },
        { name: "address", type: "text", value: address, label: "Address", placeholder: "Enter Address", inputType: "input", onValueChange:onValueChange },
        { name: "phone", type: "number", value: phone, label: "Phone", placeholder: "Enter Phone Number", inputType: "input", onValueChange:onValueChange },
        { name: "website", type: "text", value: website, label: "Website", placeholder: "Enter Website",  onValueChange:onValueChange, important: true, inputType: "input" }
    ]
}

export const generBasicInvoiceInfo = (state) => {
    const { invoiceDate, paymentDueDate, paymentTerms } = state;
    return [
        { label: "Invoice Date", value: moment(invoiceDate).format('DD-MM-YYYY') },
        { label: "Due Date", value: moment(paymentDueDate).format('DD-MM-YYYY') },
        { label: "Payment Term", value: paymentTerms },
    ]
}