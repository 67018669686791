import React, { Component } from 'react';
import './styles.css'
import UserDetails from './Components/Forms/UserDetails'
import CompletionPage from '../Registration/Components/Forms/CompletionPage';
import { showGrowlMessage , renderGrowlUI } from '../../Services/index';
const { createCompany } = require("../../utils/endPoints");
const { formSteps, nextStep, prevStep } = require('./Components/Forms/enums');

export default class componentName extends Component {

    state = {
        step: "User",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        companyName: "",
        overview: "",
        exampleCustomer: "",
        companyDomain: "",
        subSector: "",
        employeeCount: "",
        ceoEmail: "",
        ceoName: "",
        city: "",
        companyLogo: "",
        pitchDeck: "",
        state: "",
        country: "",
        token: "",
        loader: false,
    }

    renderForm = () => {
        const { step, token } = this.state;
        const userPayload = { getUserForm: this.getUserForm, state: this.state }
        switch (step) {
            case formSteps.User: return <UserDetails payload={userPayload} token={token} />
            case formSteps.Completion: return <CompletionPage />
            default: return <UserDetails payload={userPayload} token={token} />
        }
    }

    renderProgress = () => {
        const stages = {
            "User": false,
            "Company": false,
            "CEO": false
        }

        const { step } = this.state;
        stages[step] = true;
    }

    goToNextStep = () => {
        const { step } = this.state;
        this.setState({ step: nextStep[step]})
    }

    goToPrevStep = () => {
        const { step } = this.state;
        this.setState({ step: prevStep[step]})
    }

    getUserForm = (state) => {
        const { firstName, lastName, email, phone, password } = state;
        this.setState({ firstName, lastName, email, phone, password }, () => this.goToNextStep());
    }

    updateState = (state) =>  this.setState(state);

    render() {
        return <div>
            {renderGrowlUI(this.state, this.updateState)}
            {this.renderForm()}
        </div>
    }

    componentDidMount() {
    }

    handleCreateCompany = async () => {
        const { firstName,
            lastName,
            email,
            phone,
            password,
            companyName,
            companyDomain,
            employeeCount,
            overview,
            exampleCustomer,
            ceoEmail,
            ceoPhone,
            ceoName,
            gstNo,
            city,
            companyLogo,
            pitchDeck,
            state,
            token,
            country,
            organizationId
        } = this.state;

        const payload = {
            firstName,
            lastName,
            email,
            phone,
            password,
            companyName,
            companyDomain,
            employeeCount,
            overview,
            exampleCustomer,
            ceoEmail,
            gstNo,
            ceoPhone,
            ceoName,
            city,
            companyLogo,
            pitchDeck,
            state,
            country,
            organizationId
        }

        const result = await createCompany(payload, token);

        this.setState({ loader: false });

        if (result && result.status) {
            return this.goToNextStep();
        }
        if (result && result.code === 401) {
            return showGrowlMessage({ growlMsg: "Invitation Link Expired", growlStatus: "error", updateState: this.updateState });
        }
        showGrowlMessage({ growlMsg: result && result.message, growlStatus: "error", updateState: this.updateState });
    }
}