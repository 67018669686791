export const enums = {
    TERMS_AND_CONDITIONS : "Keep me logged in",
    WELCOME_MESSAGE: "Sign In",
    SIGN_IN: "Sign In",
    LOGIN_ACCOUNT: "Enter your email and password to sign in!",
    TERMS: "terms",
    INVALID_LOGIN_ERROR: "Invalid Credentials!, Please try with Valid ones",
    EMAIL_VALIDATION_MESSAGE: "Kindly Enter Valid Email ID or Password",
    TERMS_AND_CONDITIONS_ERROR: "Kindly Accept the Terms and Conditions",
    ERROR:"error",
    SUCCESS: "SUCCESS",
    EMAIL: "email",
    PASSWORD: "password"
}