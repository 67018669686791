import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

export const getProductColumns = ({ onEdit }) => {
   return [
        {
            name: 'Product Name',
            selector: row => row.productName,
            sortable: true,
        },
        {
            name: 'Unit',
            selector: row => row.unit,
            sortable: false,
        },
        {
            name: 'Price',
            selector: row => row.productPrice,
            sortable: false,
        },
        {
            name: 'Quantity',
            selector: row => row.productQuantity,
            sortable: false,
        },
        {
            name: 'Product Status',
            selector: row => row.productStatus,
            sortable: false,
        },
        {
            name: 'Edit',
            selector: row => <p><a className="editTag" onClick={() => onEdit(row)}><FontAwesomeIcon icon={faEdit} /></a></p>,
            sortable: false,
        },
    ]
};


export const generateProductDetailsPayload = (payload) => {
    const { state, onValueChange } = payload;
    const { productName, productStatus, productPrice, productQuantity, unit } = state; 
    return [
        { name: "productName", inputType: "input", type: "text", value: productName, label: "Product Name", placeholder: "Enter Product Name" },
        { name: "unit", important: true, type: "dropdown", values: ["pcs", "kg", "litre", "meter", "hour"], value: unit, label: "Unit", placeholder: "Select Unit", onValueChange: onValueChange, inputType: "dropdown" },
        { name: "productPrice", type: "number", value: productPrice, label: "Product Price", placeholder: "Enter Product Price", inputType: "input" },
        { name: "productQuantity", type: "number", value: productQuantity, label: "Product Quantity", placeholder: "Enter Product Quantity", inputType: "input" },
        { name: "productStatus", type: "text", value: productStatus, label: "Product Status", placeholder: "Select Product Status", values: ["Active", "InActive"], onValueChange: onValueChange, important: true, inputType: "dropdown" }
    ]
}