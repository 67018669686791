import React from 'react';

import Snackbar from '../utils/SnackBars';

export const convertLowerCase = (search) => {
  return search && search.toLowerCase();
}

export const filterBySearchTerm = (list, search) => {
  return list.filter(
    e => {
      return e && convertLowerCase(e.companyName).includes(convertLowerCase(search))
    });
}

export const filterByFundingStage = (list, fundingStage) => {
  return list.filter(
    e => {
      return e && (convertLowerCase(e.fundingStage) === convertLowerCase(fundingStage))
    });
}

export const filterBySubSector = (list, subSector) => {
  return list.filter(
    e => {
      return e && (convertLowerCase(e.subSector) === convertLowerCase(subSector));
    }
  )
}

export const showGrowlMessage = (fields) => {
  const { growlMsg, growlStatus, updateState } = fields;
  updateState({ showGrowl: true, growlStatus, growlMsg: growlMsg });
}


export const renderGrowlUI = (state, updateState) => {
  const { showGrowl, growlStatus, growlMsg } = state;
  return <Snackbar handleClose={() => { closeGrowlMessage(updateState) }} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
}

export const closeGrowlMessage = (updateState) =>  updateState({ showGrowl: false });