import React from 'react';
import localStrings from '../../i18n/localStrings';
import '../../App.css';
import './index.css';
import { withRouter } from 'react-router-dom';
import { logOut } from '../../utils/utils';
import { getShortName, getUserData } from '../../utils/reduxUtils';
import { connect } from 'react-redux';
import { compose } from 'redux';

let Header = (props) => {
    let { switchTab, backgroundColor } = props

    let { firstName ="" } = getUserData() || {};

    let optionsRef = React.createRef();

    let isVisible = false;

    const toggleUserOptions = () => {
        optionsRef.current.className = `down-option-list ${!isVisible?"show":""}`
        document.getElementById('root').onclick = !isVisible ? hideList : null
        isVisible = !isVisible;
    }
    const hideList = () => {
        if (optionsRef.current && optionsRef.current.className.split(" ")[1]==="show") optionsRef.current.className = "down-option-list";
    }

    return (
        <div style={{ backgroundColor: backgroundColor }} className='mainHeader'>
            <div className='HeaderLeft'>
                <img src={"https://onlinedevtools.in/assets/img/angular.png"} />
            </div>
            <div className='HeaderRight'>
                <div className="userButton" onClick={toggleUserOptions}>
                    <img className="userProfileIcon" src={require('../../assets/images/avatar-temp-icon.png').default} />
                    <span className="userName">{firstName}</span>
                    <img className="downIcon" src={require('../../assets/images/down-arrow-white.svg')} />   
                </div>
                <div className='down-option-list' ref={optionsRef} >
                    <div className='logoutWrapper option' onClick={() => {
                        logOut();
                    }}>
                        <img src={require('../../assets/images/share-alternitive.svg')} />
                        <span className="CODE_ONE">{localStrings('logout')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default compose(withRouter, connect())(Header);