import React, { Component , Fragment } from 'react';

import { generateTablePayload , conditionalRowStyles , getFilterPayload } from "../Sales/service/index";

import { renderGrowlUI, showGrowlMessage } from '../../Services/index';

import DataTable from '../../Components/DataTable/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { fetchInvoices } from "../../utils/endPoints"

import "./style.css"

export default class Sales extends Component {

    state = {
        columns: [],
        data: [],
        totalRows: 4,
    }


    componentDidMount() {
        this.setState({
            columns: generateTablePayload({ onEdit: this.onEdit }),
            data: []
        });
        this.triggerListOfProducts();
    }

    onValueChange = (event) => { this.setState({ [event.target.name]: event.target.value }) };


    updateState = (state) => this.setState(state);

    triggerListOfProducts = () => {

        const { newPerPage, page } = this.state;

        this.fetchInvoiceList(newPerPage, page);
    }

    applyFilter  = () => {
        const { searchTerm } = this.state;

        if (!searchTerm) return showGrowlMessage({ growlMsg: "Search Products By Product Name", growlStatus: "error", updateState: this.updateState });

        this.triggerListOfProducts();
    }

    fetchInvoiceList = async (newPerPage, page) => {

        this.setState({ loading: true });

        let { searchTerm , invoiceStatus = "" } = this.state;

        if (!invoiceStatus) invoiceStatus = (window.location.pathname === "/sales") ? ["PAID" , "UNPAID"] : ["DRAFT"];

        const { result = [], totalRows = 10 } = await fetchInvoices({ pageNumber: page, limit: newPerPage , searchTerm: Number(searchTerm) , invoiceStatus });

        this.setState({ data: result, totalRows, loading: false });
    }

    onChangePage = (page) => this.fetchInvoiceList(10, page);

    onChangeRowsPerPage = (newPerPage, page) =>  this.fetchInvoiceList(newPerPage, page);

    onValueChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            this.triggerListOfProducts();
        });
    }
    onFilterValueChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    onEdit = (row) => {

        const { invoiceNumber } = row;
        
        window.location.href = `${window.location.origin}/invoice?id=${invoiceNumber}`;
    }

    renderRadioButton = (payload = {}) => {
        const { name, value, label, inputValue } = payload;
        return <div>
            <input type="radio" onChange={this.onValueChange} className="" value={value} name={name} checked={inputValue === value} />
            <label> {label} </label>
        </div>
    }

    render() {

        const { data = [] , totalRows = 0 , loading = false , columns = [] , invoiceStatus ="" } = this.state;

        const isSales = (window.location.pathname === "/sales");

        return (
            <Fragment>
                {renderGrowlUI(this.state, this.updateState)}
                <div className="layout-alignment dataTableMargin" id="dataTable">
                    <div className="addNewProductButtonUI">
                        <h2> {isSales ? 'Invoices' : 'Quotations'}  </h2>
                        <button className="addNewProductButton" onClick={() => window.location.href = `${window.location.origin}/invoice` }> <FontAwesomeIcon icon={faEdit} /> Add New {isSales ? "Invoice" : "Quotation"}</button>
                    </div>
                    
                    {isSales ? <div className="invoice-filter-ui">
                        {getFilterPayload(invoiceStatus).map(each => this.renderRadioButton(each))}
                    </div> : null}

                    <div className="">
                        <DataTable conditionalRowStyles={conditionalRowStyles} title={`Manage ${isSales ? 'Invoices' : 'Quotations'} (${totalRows})`} subHeader={true}
                            onFilterValueChange={this.onFilterValueChange} selectableRows={true} data={data} columns={columns} totalRows={totalRows}
                            loading={loading} onChangePage={this.onChangePage} applyFilter={this.applyFilter}
                            refreshFilter={this.triggerListOfProducts}
                            onChangeRowsPerPage={this.onChangeRowsPerPage} pagination={true}  />
                    </div>
                </div>
            </Fragment>
        )
    }
}
