import _ from 'lodash';

const axios = require('./axiosInterceptor');

const { PLACEMENT_MASTER_SERVICE } = require('./urls');

const getHeaderConfig = () => {
  const { getToken } = require('./reduxUtils');
  return {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
}

export const loginUser = async (payload) => {
  try {
    const URL = `${PLACEMENT_MASTER_SERVICE}/login`;
    const response = await axios.post(URL, payload);
    return { status: 'SUCCESS', data: response.data }
  } catch (error) {
    return { status: 'FAILED' }
  }
};

export const signUp = async (payload, signUpToken) => {
  try {
    const URL = `${PLACEMENT_MASTER_SERVICE}/signUp`;
    const response = await axios.post(URL, payload, {
      headers: { Authorization: `Bearer ${signUpToken}` }
    });
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) return { status: false, code: 401, message: "Link Expired" }
    return { status: false }
  }
};

export const getSignedURL = async (file, authToken, path = "") => {

  const filePayload = {
    fileName: file.name,
    type: file.type,
  }

  if (path) filePayload.savePath = path;

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/getSignedUrl`, filePayload, {
    headers: { Authorization: `Bearer ${authToken}` }
  });

  return response.data;
};

export const uploads3Files = async (url, file) => {

  const s3Config = {
    header: {
      'Content-Type': file.type
    }
  }
  const response = await axios.put(url, file, s3Config);

  return response.status;
};


export const fileUpload = async (file, authToken, path) => {
  const { getToken } = require('./reduxUtils');
  try {
    const response = await getSignedURL(file, (authToken ? authToken : getToken()), path);

    if (response.result && response.result.signedURL) {

      const result = response.result;

      const statusCode = await uploads3Files(result.signedURL, file);

      if (statusCode === 200) return result.fileURL;
    }

    return false;

  } catch (error) {
    return false;
  }
}

export const cancelUpload = async (fileURL, authToken) => {
  const { getToken } = require('./reduxUtils');
  try {
    const payload = { fileURL };
    const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/deleteObjectFromS3`, payload, {
      headers: { Authorization: `Bearer ${(authToken ? authToken : getToken())}` }
    });
    return response.data;
  } catch (error) {
    return { status: false, message: "Unable To Delete File, Please try Later." }
  }
}

export const userInvite = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/userInvite`, payload, getHeaderConfig());

  return response.data;
};


export const forgotUserPassword = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/forgotPassword`, payload, getHeaderConfig());

  return response.data;
};


export const updateCompany = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/updateCompany`, payload, getHeaderConfig());

  return response.data;
};

export const fetchCompany = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchCompany`, payload, getHeaderConfig());

  return response.data;
};


export const changeUserPassword = async (payload, passwordToken) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/changePassword`, payload, {
    headers: { Authorization: `Bearer ${passwordToken}` }
  });

  return response.data;
};

export const updateProducts = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/updateProducts`, payload, getHeaderConfig());

  return response.data;
};

export const fetchProduct = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchProduct`, payload, getHeaderConfig());

  return response.data;
};


export const fetchProducts = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchProducts`, payload, getHeaderConfig());

  return response.data;
};


export const updateCustomers = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/updateCustomers`, payload, getHeaderConfig());

  return response.data;
};

export const fetchCustomers = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchCustomers`, payload, getHeaderConfig());

  return response.data;
};


export const fetchCustomer = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchCustomer`, payload, getHeaderConfig());

  return response.data;
};


export const updateInvoice = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/updateInvoice`, payload, getHeaderConfig());

  return response.data;
};

export const createInvoice = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/createInvoice`, payload, getHeaderConfig());

  return response.data;
};

export const fetchInvoice = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchInvoice`, payload, getHeaderConfig());

  return response.data;
};


export const fetchInvoices = async (payload) => {

  const response = await axios.post(`${PLACEMENT_MASTER_SERVICE}/fetchInvoices`, payload, getHeaderConfig());

  return response.data;
};