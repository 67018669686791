import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux'
import Invoices from './index'

function InvoicesContainer(props) {
    return (
        <div className='mainContainer'>
            <Invoices history={props.history} />
        </div>
    )
}

export default compose(withRouter, connect())(InvoicesContainer);
