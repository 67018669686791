import React from 'react';
import './style.css'

const EmptyState = () => {
    return (
        <div className='empty-state-container'>
            <div empty>
                <img src={require('../../../assets/images/emty-state-square.svg')} alt="No Entries Found" />
                <p>Coming Soon</p>
            </div>
        </div>
    )
}

export default EmptyState;