import React, { Component } from 'react';
import { showGrowlMessage, renderGrowlUI } from '../../Services/index';
import { CEOForm, FILE_UPLOAD_SCREEN } from '../../utils/enums';
import _ from 'lodash';
import "./style.css";
const { updateCompany } = require("../../utils/endPoints");
const { renderLoader, renderCompanyLayout, renderCEOLayout, renderSubmitButton } = require("../Registration/Components/service/index");
const { removeUploadedFile, onFileUpload, ceoFormValidation, companyFormValidation } = require("../Registration/Components/service/method");
const RE_DIRECTION_TIMEOUT = 1000;
const { renderUploadModel } = require("../../utils/fileUploadModel");
const { fetchCompanyDetails } = require("../Settings/formPayload");

export default class Settings extends Component {
    state = {
        step: "Company",
        companyName: "",
        overview: "",
        exampleCustomer: "",
        companyDomain: "",
        employeeCount: "",
        ceoEmail: "",
        ceoName: "",
        city: "",
        companyLogo: "",
        pitchDeck: "",
        state: "",
        country: "",
        token: "",
        loader: false,
        sectorList: [],
        subSectorList: [],
        stateList: [],
        cityList: [],
        countryList: [],
        listOfCountry: [],
        listOfStates: [],
        showLoader: false,
        visible: false,
        uploadType: FILE_UPLOAD_SCREEN.MY_COMPUTER,
        selectedFileId: "",
        listOfFiles: [],
        searchUrl: "",
        error: {
            companyNameError: false,
            domainError: false,
            sectorError: false,
            subSectorError: false,
            employeeError: false,
            overviewError: false,
            businessModelError: false,
            exampleCustomerError: false,
            cityError: false,
            countryError: false,
            stateError: false,
            fundingStageError: false,
            ceoNameError: false,
            ceoEmailError: false,
            ceoPhoneError: false,
            logoError: false,
            pitchDeckError: false
        }
    }
    render() {
        const { showLoader } = this.state;
        return (
            <div>
                {renderGrowlUI(this.state, this.updateState)}
                <div className=''> {showLoader ? <div className="loader-layout"> {renderLoader()} </div> :
                this.renderForm()}</div>
            </div>
        );
    }


    renderForm = () => {

        return (<div className="flexCenterLayout">


            <div className="signUpLayout">

                {renderUploadModel({ state: this.state, updateState: this.updateState, onFileUpload, props: this.props, onValueChange: this.onValueChange })}

                <div className="flexInviteButtonSpace">
                    <p className='text-login-Welcome-header-center'>{CEOForm.ADD_COMPANY_DETAILS}</p>
                </div>

                <div className="section-space-between">
                    <div className="section-half-layout">
                        <h3> {CEOForm.COMPANY_DETAILS} </h3>
                        {renderCompanyLayout({ state: this.state, onValueChange: this.onValueChange })}
                    </div>
                    <div className="section-half-layout">
                        <h3> {CEOForm.LOCATION_DETAILS} </h3>
                        {renderCEOLayout({
                            state: this.state, onValueChange: this.onValueChange,
                            props: this.props, updateState: this.updateState, removeUploadedFile, onFileUpload
                        })}
                        {renderSubmitButton({ state: this.state, onPress: () => { ceoFormValidation({ state: this.state, updateState: this.updateState, }) } })}
                    </div>
                </div>
            </div>
        </div>)
    }

    componentDidMount = async () => {
        await fetchCompanyDetails(this.updateState);
    }

    updateState = (state) => this.setState(state, () => {
        if (_.get(state, CEOForm.FORM_NAME) === CEOForm.CEO) {
            const companyFormResult = companyFormValidation({ state: this.state, updateState: this.updateState });
            if (companyFormResult || _.get(state, CEOForm.VALID_CEO_FORM)) return;
            this.handleCreateCompany();
        }
    });


    onValueChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleCreateCompany = async () => {

        this.updateState({ loader: true });

        const {
            companyName,
            companyDomain,
            businessType,
            categories,
            address,
            employeeCount,
            overview,
            ceoEmail,
            ceoName,
            city,
            companyLogo,
            pitchDeck,
            state,
            country,
            pinCode,
            gstNo,
            ceoPhone,
        } = this.state;

        const addCompanyPayload = {
            gstNo,
            ceoPhone,
            companyName,
            companyDomain,
            businessType,
            categories,
            overview,
            address,
            employeeCount,
            ceoEmail,
            ceoName,
            companyLogo,
            pitchDeck,
            city,
            state,
            country,
            pinCode,
        }

        const result = await updateCompany(addCompanyPayload);

        this.setState({ loader: false });

        if (result && result.status) {
            showGrowlMessage({ growlMsg: this.getMessageFromResult(result), growlStatus: "success", updateState: this.updateState });
            this.reDirectToDashboardPage();
            return;
        }
        showGrowlMessage({ growlMsg: this.getMessageFromResult(result), growlStatus: "error", updateState: this.updateState });
    }

    getMessageFromResult = (result) => _.get(result, "message");

    reDirectToDashboardPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, RE_DIRECTION_TIMEOUT);
    }
}

