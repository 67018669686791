import React, { Component } from 'react';
import './../../styles.css'
import Button from '../../../../Components/FormComponents/Button'
import Loader from "react-loader-spinner";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux'


class CompletionPage extends Component {
    state = {
        // showLoader: true
    }


    gotoLogin = () => {
        this.props.history.replace("/login");
    }

    render() {
        return (
            <div>
                <div className='login-and-registration'>
                    <div className='login-and-registration-left'>
                        <img className='login-logo' src={require('../../../../assets/images/logo.svg')} />
                    </div>
                    <div className='login-and-registration-right'>
                        {
                            this.state.showLoader ? <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={2000} //3 secs
                            /> : (

                                    <div className='login-form finish-form-wrapper' style={{paddingBottom:"49.5px"}}>
                                        <img className='common-field-margin-top' src={require('../../../../assets/images/greenTick.svg')} />
                                        <p className='form-filled-text'>Your application has been received.  We will connect very soon with you.</p>
                                        <div className='common-button-margin-top'>
                                            <Button onPress={() => { this.gotoLogin()  }} name='Okay' />
                                        </div>
                                        {/* <p className='text-apply-another'>Apply another application</p> */}
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}


export default compose(withRouter, connect())(CompletionPage);