export const fixedValues = {
    currencyList: [
        { label: '(₹ | INR) Indian Rupee', value: "INR" },
        { label: '($ | USD) US Dollar', value: "USD" },
        { label: '(€ | EUR) Euro', value: "EUR" },
    ],
    paymentList: [
        { label: 'Others', value: "Others" },
        { label: 'Cash Payment', value: "Cash Payment" },
        { label: 'Bank Transfer', value: "Bank Transfer" },
        { label: 'UPI', value: "UPI" },
    ]
};