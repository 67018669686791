import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { getToken } from '../../utils/reduxUtils';
import ProtectedRoute from '../ProtectedRoutes/index';
import Login from '../../screens/Login'
import Registration from '../../screens/Registration'
import ForgotPasswordContainer from '../../screens/ForgotPassword/container';
import SalesContainer from '../../screens/Sales/container';
import PricingContainer from '../../screens/Pricing/container';
import EmptyState from '../../screens/Dashboard/EmptyStateScreen/index';
import Settings from '../../screens/Settings/index';
import InvoicesContainer from '../../screens/Invoices/container';
import ProductsContainer from '../../screens/Products/container';
import CustomersContainer from '../../screens/Customers/container';


export class Navigator extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/sign-up" component={Registration} />
          <Route path="/change-password" component={ForgotPasswordContainer} />
          <Route path="/forgot-password" component={ForgotPasswordContainer} />
          <ProtectedRoute path="/invoice" component={InvoicesContainer} />
          <ProtectedRoute path="/sales" component={SalesContainer} />
          <ProtectedRoute path="/quotation" component={SalesContainer} />
          <ProtectedRoute path="/subscriptions" component={PricingContainer} />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/customers" component={CustomersContainer} />
          <ProtectedRoute path="/reports" component={EmptyState} />
          <ProtectedRoute path="/products" component={ProductsContainer} />
          <Route
            render={() => {
              if (!getToken()) return <Login />
              return <Redirect to='/sales' />
            }}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}
export default Navigator
