import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function MaterialUIPickers(props) {
    const { label = "" , value = "" , onChange , name = ""} = props; 
    const [inputValue, setValue] = React.useState(value);
    const handleChange = (newValue) => { 
    setValue(newValue)
        onChange({ name, value: newValue });
   };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} >
            <Stack spacing={4}>
                <DesktopDatePicker
                    name={name}
                    label={label}
                    inputFormat="MM/dd/yyyy"
                    value={inputValue}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}
