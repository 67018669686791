const USER_ACTION = {

    /** LOGIN ACTIONS */
    SET_USER_INFO: 'SET_USER_INFO',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    LOGOUT_USER: 'LOGOUT_USER',
    SET_AUTH_TOKEN: "SET_AUTH_TOKEN"
    
}

export default USER_ACTION;
