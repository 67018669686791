import React from 'react';
import TextInput from '../../Components/FormComponents/TextInput';

export const renderInputFields = (fields , onValueChange) => {
    const { name, type, value, label, placeholder , showForgotPassword } = fields;
    return <div className='common-field-margin-top'>
        <TextInput name={name} inputStyleClass={"default-input-style"} type={type} value={value} label={label} showForgotPassword={showForgotPassword} isImportant={true}
            onChange={(e) => onValueChange(e)} placeholder={placeholder} />
    </div>
}
export const renderCheckBox = (fields, onValueChange) => {
    const { name, type, value, label } = fields;
    return <div className='termsAndConditions-checkbox-wrapper'>
        <div className='checkboxWrapper font13'>
            <input type={type} name={name} value={value}
                onChange={(e) => onValueChange(e)}></input>
            <label>{label}</label>
        </div>
    </div>
}