import actionType from '../type/index';

const {
    LOGOUT_USER,
    SET_USER_INFO,
    UPDATE_USER_INFO,
    SET_AUTH_TOKEN,
} = actionType;

const initialState = {
    User: {},
    AuthToken: ""
}

const loginReducer = (state = initialState, action = "") => {

    const { User } = state;

    switch (action.type) {

        case SET_USER_INFO: {
            const UserData = action.payload;
            return {
                ...state,
                User: UserData
            }
        }

        case UPDATE_USER_INFO: {
            const newUserInfo = action.payload;
            return {
                ...state,
                User: { ...User, ...newUserInfo }
            }
        }

        case SET_AUTH_TOKEN: {
            const AuthToken = action.payload;
            return {
                ...state,
                AuthToken
            }
        }

        case LOGOUT_USER: {
            return initialState;
        }

        default:
            return state
    }
}

export default loginReducer;
