
import { enums, reUploadConfirmMessage } from '../Forms/enums';

import { showGrowlMessage } from '../../../../Services/index';

const { fileUpload, cancelUpload , updateCompanyDetails } = require("../../../../utils/endPoints");

const { getFileSizeInMb } = require("../../../../utils/utils");

const { isInvalid } = require("../../../../utils/validations");

const { FILE_UPLOAD_SCREEN, COMPANY_DATA_PREFIX } = require("../../../../utils/enums");

const MAX_FILE_SIZE = 20;

const _ = require('lodash');

export const removeUploadedFile = async (fields) => {

    const { event, props, state, updateState } = fields;

    let { token } = props || {};

    let target = event.target.name;

    let fileURL = state[target];

    const fileType = event.target.accept;

    event.preventDefault();

    if (!fileURL) {

        return updateState({ visible: true, searchUrl: "", targetName: target, fileType, selectedFileData: {}, selectedFileId: "" });
    }

    if (!window.confirm(reUploadConfirmMessage)) return event.preventDefault();

    const response = await cancelUpload(fileURL, token);

    if (response && response.status) {

        updateState({ [target]: "", fileLoader: false, visible: true, targetName: target, fileType, selectedFileData: {}, selectedFileId: "", searchUrl: "" });
        return showGrowlMessage({ growlMsg: response.message, growlStatus: FILE_UPLOAD_SCREEN.SUCCESS, updateState })
    }
    response && showErrorMsg(response.message, updateState);

    updateState({ fileLoader: false });
}


export const onFileUpload = async (fields) => {

    const { event, updateState, props, companyId = "", folderName = "", callback = false } = fields;

    if (event.target.type === 'file' && event.target.files && event.target.files.length) {

      const { token } = props;

        const fileName = event.target.name;

        const file = event.target.files[0];

        let payload = { fileName, file, token, updateState, accept: event.target.accept, props }
        
        if ((companyId || folderName) && callback) payload = { ...payload, savePath: `${COMPANY_DATA_PREFIX}${companyId}/${folderName}/`, callback }

        fileValidation(payload);
    }
}


export const fileValidation = async (fields) => {

    const { file, fileName, token, updateState, accept , props, savePath, callback } = fields;

    if (getFileSizeInMb(file) >= MAX_FILE_SIZE) {
      return showGrowlMessage({ growlMsg: enums.FILE_SIZE_ERROR, growlStatus: enums.ERROR, updateState });
    }

    updateState({ fileLoader: true });

    await fileFileToS3({ file, fileName, token, updateState , props, savePath, callback });
}



export const fileFileToS3 = async (fields) => {

    const { file, fileName, token, updateState , props, savePath="", callback=false } = fields;

    try {
        const response = await fileUpload(file, token, savePath);

        if (response) {
          if (callback) callback(response);
          return storeFileValue(fileName, response, updateState, props);

        }

        showErrorMsg(FILE_UPLOAD_SCREEN.FILE_UPLOAD_ERROR, updateState);

    } catch (error) {
        showErrorMsg(FILE_UPLOAD_SCREEN.FILE_UPLOAD_ERROR, updateState);
    }
}

const showErrorMsg = (msg, updateState) => {

    updateState({ fileLoader: false });

    showGrowlMessage({ growlMsg: msg, growlStatus: enums.ERROR, updateState });

}
const storeFileValue = async (fileName, value, updateState , props) => {

    updateState({ [fileName]: value, fileLoader: false });

    if (fileName === 'memoDocument') {
        await updateCompanyDetails({ memoDocument: value , email: _.get(props , 'location.state.emailId') });
    }

    showGrowlMessage({ growlMsg: FILE_UPLOAD_SCREEN.FILE_UPLOAD_SUCCESS, growlStatus: FILE_UPLOAD_SCREEN.SUCCESS, updateState });
}


export const ceoFormValidation = (field) => {

    const { state, updateState } = field;

    const { pinCode, ceoName, ceoEmail, country, error , ceoPhone } = state;

    const countryError = isInvalid("empty", country);

    const pinCodeError = isInvalid("empty", pinCode);

    const ceoNameError = isInvalid("name", ceoName);

    const ceoEmailError = isInvalid("email", ceoEmail);

    const ceoPhoneError = isInvalid("empty", ceoPhone);

    const isValidCEOForm = pinCodeError || ceoNameError || ceoEmailError || countryError || ceoPhoneError;

    updateState({
        error:
            { ...error,ceoPhoneError, pinCodeError, ceoNameError, ceoEmailError, countryError, formName: "CEO", isValidCEOForm }
    });

    return isValidCEOForm;
}

export const companyFormValidation = (field) => {

    const { state, updateState } = field;

    const { companyName, companyDomain, employeeCount, error , businessType , categories , address , gstNo } = state;

    let companyNameError = isInvalid('companyName', companyName);

    let domainError = isInvalid('domain', companyDomain);

    let gstNoError = isInvalid('empty', gstNo);

    let employeeError = isInvalid('empty', employeeCount);

    const businessTypeError = isInvalid("empty", businessType);

    const categoriesError = isInvalid("empty", categories);

    const addressError = isInvalid("empty", address);

    updateState({ error: { ...error, gstNoError, categoriesError, addressError, businessTypeError, companyNameError, domainError, employeeError, formName: "Company" } });

    return (companyNameError || gstNoError || domainError || employeeError || businessTypeError || categoriesError || addressError);
}