import React, { Component, Fragment } from 'react';
import "./style.css";
import {
    renderInvoiceSummaryUI, renderBuyerModel, renderSellerModel, renderModel, renderSellerInfoLayout,
    renderBasicInvoiceDetails, renderNewInvoiceUI, renderReviewButton,renderQuotation,renderConvertQuotation,
    renderSearchProductUI
} from './method';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { fixedValues } from "../../utils/const";
import DataTable from '../../Components/DataTable/index';
import { generateTablePayload } from "./formPayload";
import _ from 'lodash'
import { renderGrowlUI } from '../../Services/index';
const { fetchInvoice } = require("../../utils/endPoints");
const { handleCalculateTotal, getInvoiceIdFromQueryParams, addNewInvoice } = require("./formula");

export default class Invoices extends Component {

    state = {
        currency: "INR",
        currencyList: fixedValues.currencyList,
        paymentList: fixedValues.paymentList
    }

    componentDidMount = async () => {

        const invoiceNumberFromQuery = getInvoiceIdFromQueryParams();

        if (invoiceNumberFromQuery) {
            const { result = {} } = await fetchInvoice({ invoiceNumber: invoiceNumberFromQuery });
            if (!_.isEmpty(result)) {
                const { invoiceDate, paymentDueDate, paymentTerms, currency, invoiceData,invoiceStatus,
                    sellerInfo, buyerInfo, remarks, discountAmount, taxAmount, total, subTotal, dueAmount, advanceAmount, invoiceNumber = "" , invoiceOrderCount = "" } = result;
                this.setState({
                    invoiceDate, paymentDueDate, paymentTerms, currency, data: invoiceData,invoiceStatus,
                    sellerInfo, buyerInfo, remarks, discountAmount, taxAmount, total, subTotal, dueAmount, advanceAmount, invoiceNumber , invoiceOrderCount
                });
            } else this.initialStateValue()
        } else {
            this.initialStateValue()
        }
    }

    initialStateValue = () => {
        this.setState({
            paymentTerms: "Cash Payment",
            invoiceDate: new Date(),
            paymentDueDate: new Date(),
            currency: "INR",
            remarks: "Thank You For Your Business",
            data: [
                {
                    item: "Coke",
                    unit: "PCS",
                    description: "No Tax",
                    quantity: 1,
                    price: 50,
                    total: 1,
                    taxRate: 0,
                    discountRate: 0,
                    taxAmount: 0,
                    discountAmount: 0,
                    edit: true
                }
            ]
        }, () => handleCalculateTotal({
            state: this.state, updateState: this.updateState,
        }));
    }

    onDelete = (row, index) => {
        const { data = [] } = this.state;
        data.splice(index, 1);
        this.setState({ data }, () => handleCalculateTotal({ state: this.state, updateState: this.updateState }));
    }

    onTableValueChange = (event, index) => {

        const { value, name } = event.target;

        const { data = [] } = this.state;

        data[index][name] = value;

        this.setState({ [name]: value, data }, () => handleCalculateTotal({
            state: this.state, updateState: this.updateState,
        }));

    }

    onValueChange = (event) => {

        const { value, name } = event.target;

        this.setState({ [name]: value });
    }

    onValueDateChange = (event) => {

        const { value, name } = event;

        this.setState({ [name]: value });
    }

    onValueChangeAdvance = (event) => {

        const { value, name } = event.target;

        this.setState({ [name]: value }, () => handleCalculateTotal({
            state: this.state, updateState: this.updateState,
        }));
    }

    // https://kernel-storage.fra1.cdn.digitaloceanspaces.com/kernel_company_logos/logo_40529_839dff35-e3fb-11ec-8000-000000000000.png

    updateState = (state) => this.setState(state);

    renderPopModels = () => {
        return <Fragment>
            {renderModel({ state: this.state, updateState: this.updateState })}
            {renderGrowlUI(this.state, this.updateState)}
            {renderSellerModel({ state: this.state, updateState: this.updateState, onValueChange: this.onValueChange })}
            {renderBuyerModel({ state: this.state, updateState: this.updateState, onValueChange: this.onValueChange })}
        </Fragment>
    }

    renderTableLayout = () => {
        const { data = [], totalRows = 0, loading = false, columns = generateTablePayload({ onDelete: this.onDelete, onTableValueChange: this.onTableValueChange }) } = this.state;
        return <div>
            <div className="" id="dataTable">
                <DataTable title={`Create Invoice`}
                    onFilterValueChange={this.onFilterValueChange} selectableRows={true} data={data} columns={columns} totalRows={totalRows}
                    loading={loading} onChangePage={this.onChangePage} applyFilter={this.applyFilter}
                    refreshFilter={this.triggerListOfProducts} subHeader={false}
                    onChangeRowsPerPage={this.onChangeRowsPerPage} selectableRows={false} pagination={false} />
            </div>
            <div className="addItem"> <span className="addNewInvoiceIcon"><FontAwesomeIcon icon={faPlusCircle} onClick={() => { addNewInvoice({ state: this.state, updateState: this.updateState, }) }} />
            </span><span onClick={() => { addNewInvoice({ state: this.state, updateState: this.updateState, }) }} >Add Item</span></div>
        </div>
    }
    render() {
        const { invoiceNumber , invoiceStatus = "" } = this.state;
        return (
            <Fragment>
                {this.renderPopModels()}
                <div className="layout-alignment">
                    {renderNewInvoiceUI({  state: this.state, updateState: this.updateState, invoiceNumber , invoiceStatus })}
                    {renderBasicInvoiceDetails({ state: this.state, onValueDateChange: this.onValueDateChange })}
                    {renderSellerInfoLayout({ state: this.state, updateState: this.updateState })}
                    <br></br>

                    <div id="searchProduct">{renderSearchProductUI({ state: this.state, onValueDateChange: this.onValueDateChange })}</div>

                    {this.renderTableLayout()}
                    <hr className="hrInvoice"></hr>
                    {renderInvoiceSummaryUI({
                        state: this.state, onValueChange: this.onValueChange,
                        onValueChangeAdvance: this.onValueChangeAdvance,
                    })}
                    <div className="flex">
                    {renderQuotation({ state: this.state, updateState: this.updateState, invoiceNumber , invoiceStatus})}
                    {renderReviewButton({ state: this.state, updateState: this.updateState })}
                    {renderConvertQuotation({ state: this.state, updateState: this.updateState, invoiceNumber , invoiceStatus})}
                </div>
                </div> 
            </Fragment>
        )
    }
}
