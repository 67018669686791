import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux'
import Sales from './index'

function SalesContainer(props) {
    return (
        <div className='mainContainer'>
            <Sales history={props.history} />
        </div>
    )
}

export default compose(withRouter, connect())(SalesContainer);
