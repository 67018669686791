const headers = ["#", "Company / Org Name", "Sector / Sub-Sector", "City / State", "Funding Stage", "Application"];

const openApplicationHeaders = ["#", "Company / Deal Name", "Target Close", "Notes", "Vertical", "Deal Source", "Deal Structure", "Deal Size", "Term Years", "Collateral Type", "Collateral Description", "Pref / Coupon", "Revenue Share", "Warrants", "Risk Weighting", "Location", "Funding Stage", "Priority", "Application"];

const viewApplication = 'View Application';

const VIEW_COMPANY = 'View Company';

const stateList = [
    "Assam",
    "Goa",
    "Madhya Pradesh",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "National Capital Territory of Delhi",
    "Sikkim",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Bihar",
    "Chhattisgarh",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Maharashtra",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "Uttarakhand",
    "Telangana",
    "Tripura",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Di",
    "Lakshadweep",
    "Puducherry",
    "Uttar Pradesh",
    "West Bengal"
]


module.exports = { stateList, headers, viewApplication, VIEW_COMPANY, openApplicationHeaders }
