import React from 'react';

import "../style.css"

import { dashboardPages } from '../../../utils/enums';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faStore , faFileAlt  , faAddressBook , faStoreAlt , faMoneyBill, faBook , faShoppingCart , faBullseye } from '@fortawesome/free-solid-svg-icons';

export let sidNavOptionList = React.createRef();

const {
    subscriptionScreen,settingsScreen,
    reportScreen, invoiceScreen, salesScreen, customerScreen,quotationScreen,
    productsScreen,
} = dashboardPages;

const generateSideBarPayload = (currentPath) => generateUrbanMenu(currentPath)

const generateUrbanMenu = () => {

    const currentPath = window.location.pathname;

    return [
        {
            label: "Invoice",
            icon: faBook,
            className: currentPath === invoiceScreen ? "sidnav-option--active" : "sidnav-option",
            value: invoiceScreen
        },
        {
            label: "Invoice List",
            icon: faBullseye,
            className: currentPath === salesScreen ? "sidnav-option--active" : "sidnav-option",
            value: salesScreen
        },
        {
            label: "Quotation",
            icon: faMoneyBill,
            className: currentPath === quotationScreen ? "sidnav-option--active" : "sidnav-option",
            value: quotationScreen
        },
        {
            label: "Customers",
            icon: faAddressBook,
            className: currentPath === customerScreen ? "sidnav-option--active" : "sidnav-option",
            value: customerScreen
        },
        {
            label: "Products",
            icon: faStoreAlt,
            className: currentPath === productsScreen ? "sidnav-option--active" : "sidnav-option",
            value: productsScreen
        },
        {
            label: "Reports",
            icon: faFileAlt,
            className: currentPath === reportScreen ? "sidnav-option--active" : "sidnav-option",
            value: reportScreen
        },
        {
            label: "Subscription",
            icon: faShoppingCart,
            className: currentPath === subscriptionScreen ? "sidnav-option--active" : "sidnav-option",
            value: subscriptionScreen
        },
        {
            label: "Settings",
            className: currentPath === settingsScreen ? "sidnav-option--active" : "sidnav-option",
            icon: faStore,
            value: settingsScreen
        },
    ];
}

let makeOptionActive = (e) => {
    sidNavOptionList.current.childNodes.forEach((element, index) => {
        sidNavOptionList.current.childNodes[index].className = 'sidnav-option'
    });
    if (e.currentTarget.className === 'sidnav-option') e.currentTarget.className = 'sidnav-option--active'
}
const renderSideMenu = (inputFields, context) => {
    const { label, icon, value, className } = inputFields;

    const entryRef = React.createRef(null);

    return <div key={label} className={className}
        style={{ position: "relative" }}
        onMouseEnter={e => {
            entryRef.current.style.opacity = 1;
            entryRef.current.style.transition = "opacity 0.3s linear";
        }}
        onMouseLeave={e => {
            entryRef.current.style.opacity = 0;
            entryRef.current.style.transition = "opacity 0.1s linear";
        }}
        onClick={(e) => {
            makeOptionActive(e);
            window.location.pathname = value;
        }}>

        <FontAwesomeIcon className="MenuIcon" icon={icon} />
        <div className="sideBarToolTip" ref={entryRef}>{label}</div>
    </div>
}
export default (props) => {
    let { context, currentPath } = props;
    const sideBarFormPayload = generateSideBarPayload(currentPath);
    return (
        <div className="row" id="body-row">
            <div id="sidebar-container" className="sidebar-collapsed">
                <ul className="list-group">
                    <div className='sidnav'>
                        <div className='sidnav-optionList' ref={sidNavOptionList}>
                            {sideBarFormPayload.map(field => renderSideMenu(field, context))}
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    )
}