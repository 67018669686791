const { fetchCompany } = require("../../utils/endPoints");

const _ = require("lodash")

export const fetchCompanyDetails = async (updateState) => {

    updateState({ loader: true });

    const { status = false, result = {} } = await fetchCompany();

    if (status && !_.isEmpty(result)) updateState(extractCompanyDetails(result));
    
    updateState({ loader: false });
}

export const extractCompanyDetails = (companyDetails = {}) => {
    const {
        companyName = "",
        companyDomain = "",
        businessType = "",
        categories = "",
        overview = "",
        address = "",
        employeeCount = "",
        ceoEmail = "",
        ceoName = "",
        companyLogo = "",
        pitchDeck = "",
        city = "",
        state = "",
        country = "",
        pinCode = "",
        gstNo = "",
        ceoPhone = "",
    } = companyDetails || {};

    return {
        gstNo,
        ceoPhone,
        companyName,
        companyDomain,
        businessType,
        categories,
        overview,
        address,
        employeeCount,
        ceoEmail,
        ceoName,
        companyLogo,
        pitchDeck,
        city,
        state,
        country,
        pinCode,
    }
}