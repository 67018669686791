import _ from 'lodash'

export const handleCalculateTotal = (payload) => {

    const { state, updateState } = payload;

    const { data = [], advanceAmount = 0 } = state;

    data.map((items) => {
        let subTotal = 0;
        const total = subTotal + ((Number(items.price)) * (Number(items.quantity)))
        const taxAmount = parseFloat(parseFloat(total) * (items.taxRate / 100)).toFixed(2);
        const discountAmount = parseFloat(parseFloat(total) * (items.discountRate / 100)).toFixed(2);
        items.taxAmount = parseFloat(taxAmount);
        items.discountAmount = parseFloat(discountAmount);
        items.subTotal = total;
        items.total = ((total - discountAmount) + parseFloat(taxAmount))
    });

    const discountAmount = _.sumBy(data, 'discountAmount');

    const taxAmount = _.sumBy(data, 'taxAmount');

    const total = _.sumBy(data, 'total');

    const subTotal = _.sumBy(data, 'subTotal');

    const dueAmount = (total - advanceAmount);

    updateState({ data, discountAmount, taxAmount, total, subTotal, dueAmount });
}

export const getInvoiceIdFromQueryParams = () => {

    const params = new URLSearchParams(window.location.search);

    return params.get('id') || "";
}

export const addNewInvoice = (payload) => {

    const { state, updateState } = payload;

    const { data = [] } = state;
    
    data.push({
        item: "",
        unit: "PCS",
        description: "",
        quantity: 1,
        price: 0,
        total: 0,
        taxRate: 0,
        discountRate: 0,
        taxAmount: 0,
        discountAmount: 0,
        edit: true
    })
    updateState({ data });
}