import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function AutocompleteUI(props) {
    const [inputValue, setInputValue] = React.useState('');
    const { name, options = [], label = "", onChange, value = "" } = props;
    return (
        <Autocomplete
            name={name}
            value={value}
            id="combo-box-demo"
            loading={true}
            options={options}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            sx={{ width: "20%" , marginRight: "20px" }}
            onChange={(event, newValue) => {
                onChange({ name, value: newValue.value });
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
}
