import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux'
import Pricing from './index'

function PricingContainer(props) {
    return (
        <div className='mainContainer'>
            <Pricing history={props.history} />
        </div>
    )
}

export default compose(withRouter, connect())(PricingContainer);
