import Modal from 'react-awesome-modal';

import React from 'react';

import { showGrowlMessage } from '../Services/index';

const { FILE_UPLOAD_SCREEN } = require('../utils/enums');

const { fileValidation } = require('../../src/screens/Registration/Components/service/method');

const myComputer = React.createRef();

const { downloadLink , isUrlValid } = require("../utils/utils");

const menuBars = [
    { name: FILE_UPLOAD_SCREEN.MY_COMPUTER, imageLink: require("../assets/images/mycomputer.svg") },
    { name:FILE_UPLOAD_SCREEN.LINK, imageLink: require("../assets/images/downloadLink.png") },
    { name: FILE_UPLOAD_SCREEN.GOOGLE_DRIVE, imageLink: require("../assets/images/googledrive.svg") },
    { name: FILE_UPLOAD_SCREEN.DROP_BOX, imageLink: require("../assets/images/dropbox.png") }
];

const connectToGoogleDrive = (label, message) => {
    return <div className="fp__drag-and-drop-center">
        <button className="google-connect-button" onClick={() => {}}> {label} </button>
        <div className="model-small-text"> {message} </div>
    </div>
}

const renderMyComputer = (fields) => {

    const { updateState, state, onFileUpload, props, companyId, folderName, callback=false } = fields;

    const { targetName, fileType } = state;

    return <div className="fp__drag-and-drop-center">
        <div className="input-box-overlay" onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.effectAllowed = 'move';
            }}
            onDrop={(e) => { fileDragUpload({ e, updateState, onFileUpload, props, callback }); }}>
            <div className="drag-file-text">{FILE_UPLOAD_SCREEN.DRAG_FILE}</div>
            <br></br> <div className="model-small-text">{FILE_UPLOAD_SCREEN.OR}</div><br></br>
            <button type="file" className="google-connect-button" 
                onClick={(e) => { myComputer.current.click(); }}> {FILE_UPLOAD_SCREEN.CHOOSE_FILES}</button>
            <input className="file-input-hidden" type='file' name={targetName} accept={fileType} onChange={(e) => {
                updateState({ visible: false });
                onFileUpload({ event: e, updateState, props, companyId, folderName, callback })
            }} ref={myComputer} />
        </div>
    </div>
}

const fileDragUpload = (fields) => {
    const { updateState, onFileUpload, props, e, companyId, folderName, callback = false } = fields;

    e.preventDefault();

    let droppedFiles = e.dataTransfer.files;

    e.target = myComputer.current;

    e.target.files = droppedFiles;

    updateState({ visible: false });

    onFileUpload({ event: e, updateState, props, companyId, folderName, callback });
}

const renderLinkBox = (fields) => {

    const { onValueChange, state } = fields;

    const { searchUrl } = state;
    
    return <div className="fp__drag-and-drop-center">
        <input onChange={onValueChange} value={searchUrl} name="searchUrl" type="string" placeholder="http://example.com/someImage.jpg" className="linkBox"></input>
        <button className="google-connect-button" onClick={() => { downloadFileFromURL(fields) }}> {FILE_UPLOAD_SCREEN.SEARCH} </button>
    </div>
}

const downloadFileFromURL = (fields) => {

    const { updateState, state } = fields;

    const { searchUrl } = state;

    if (isUrlValid(searchUrl)) { return downloadLink(searchUrl); }

    updateState({ visible: false });

    showGrowlMessage({ growlMsg: FILE_UPLOAD_SCREEN.INVALID_FILE_URL_ERROR, growlStatus: FILE_UPLOAD_SCREEN.ERROR, updateState })
}

const renderHeaderTitle = (header , updateState) => {
    return <div className="content__header content__header__title">
        {"Upload From " + (header === FILE_UPLOAD_SCREEN.MY_COMPUTER ? " Your Computer" : header)}
        {header === FILE_UPLOAD_SCREEN.GOOGLE_DRIVE ? <div className="textRight" onClick={async() => { 
            updateState({ uploadType : FILE_UPLOAD_SCREEN.GOOGLE_DRIVE})
        }}> <img className="google-logout" src={require("../assets/images/logout.png")} /></div> : null}
    </div>
}

const checkValidMenuName = (actualName, expectedName) => (actualName === expectedName ? "active-side-bar-color" : "not-active-side-bar-color");

const renderSideMenu = (fields) => {

    const { updateState, state } = fields;

    const { uploadType } = state;

    return <div className="fp__sidebar">
        {menuBars.map(field => <div className={checkValidMenuName(uploadType, field.name)} onClick={() => updateState({ uploadType: field.name })}>
            <img className="model-icon-margin" src={field.imageLink} />{field.name}</div>)}
    </div>
}

const renderHeaders = (fields) => {
    const { updateState , closeModal } = fields;
    return <div className="model-file-upload-header">
        <div>{FILE_UPLOAD_SCREEN.CHOOSE_FILES}</div>
        <div class="fp__close" onClick={() => closeModal({ updateState })}><a>X</a></div>
    </div>
}

const renderModelContent = (fields) => {

    const { state } = fields;

    const { uploadType } = state;

    return <div className="fp__drag-and-drop">
        {uploadType === FILE_UPLOAD_SCREEN.GOOGLE_DRIVE &&
        connectToGoogleDrive(FILE_UPLOAD_SCREEN.GOOGLE_CONNECT,
         FILE_UPLOAD_SCREEN.DRIVE_ACCOUNT_MESSAGE)}
        {uploadType === FILE_UPLOAD_SCREEN.DROP_BOX && connectToGoogleDrive(FILE_UPLOAD_SCREEN.DROPBOX_CONNECT,
         FILE_UPLOAD_SCREEN.DROPBOX_ACCOUNT_MESSAGE)}
        {uploadType === FILE_UPLOAD_SCREEN.MY_COMPUTER && renderMyComputer(fields)}
        {uploadType ===FILE_UPLOAD_SCREEN.LINK && renderLinkBox(fields)}
        {renderListOfFilesUI(fields)}
    </div>
}

const renderListOfFilesUI = (fields) => {

    const { updateState, state } = fields;

    const { uploadType, selectedFileId, listOfFiles , showNoResults } = state;

    return checkListOfFileExistOrNot(uploadType) ? <div className="listOfFiles">
        {listOfFiles.map(file => <div onClick={() => {
            updateState({ selectedFileId: file.id, selectedFileData: file });
        }} className={selectedFileId === file.id ? "single-file selected-file" : "single-file"} id={file.id} >
            {renderFileLayout(file)}
        </div>)}
        {showNoResults ? <div className="no-records-found"> <img src={require("../assets/images/no-record.png")} /> </div> : null}
    </div> : null
}

const renderFileLayout = (file) => {
    return <div className="fileName-margin"> <span className="common-fileName">
        <img className="common-fileName" src={`https://drive-thirdparty.googleusercontent.com/32/type/${file.mimeType}`} /></span>
        <span className="common-file-name-value">{file.name}</span>
    </div>
}

const checkListOfFileExistOrNot = (uploadType) => {
    return uploadType === FILE_UPLOAD_SCREEN.GOOGLE_DRIVE
}

const clearSelectedFile = (fields) => {

    const { updateState } = fields;

    updateState({ selectedFileId: "" });
    
};

const renderUploadButton = (fields) => {
    const { state } = fields;
    const { selectedFileId } = state;
    return selectedFileId && <div className="file-upload-button">
        <button className="google-connect-button file-upload-button-margin" onClick={() => { clearSelectedFile(fields) }}> {FILE_UPLOAD_SCREEN.CLEAR_ALL} </button>
        <button className="google-connect-button"> {FILE_UPLOAD_SCREEN.UPLOAD_SCREEN} </button>
    </div>
}
export const renderUploadModel = (fields) => {

    const { updateState, state , closeModal } = fields;

    const { visible, uploadType } = state;

    return <Modal id="model" visible={visible} width="80%" height="80%" effect="fadeInUp" onClickAway={() => closeModal({ updateState })}>
        <div className="model-file-upload-height">
            <div className="model-file-upload-height">
                {renderHeaders(fields)}
                <div className="model-file-upload-flex">
                    {renderSideMenu(fields)}
                    <div className="model-file-upload-flex-two">
                        {renderHeaderTitle(uploadType , updateState)}
                        {renderModelContent(fields)}
                        {renderUploadButton(fields)}
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}