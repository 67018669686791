export const enums = {
  GLOBUS: "GLOBUS",
  URBANUS: "URBANUS",
  STARTUP: "STARTUP",
  USER: "User",
  MEMBER: "MEMBER",
}

export const applicationStatus = {
  Open: "Open",
  FutureReference: "FutureReference",
  Reject: "Reject",
  Portfolio: "Portfolio",
  Archive: "Archive"
};

export const movePipelineMapping = {
  Reject: "Pass",
  "Save For Future": "Save For Future",
  "Archive": "Archive"
}

export const subDecisionList = {
  WATCH_IT: "Watch It",
  INTERESTING: "Interesting But Wrong Timing",
  DEEP_STORAGE: "Deep Storage"
}

export const invitationTypes = {
  startup: "startup",
  globus: "globus",
  urbanus: "urbanus",
  member: "member",
};

export const stages = {
  OpportunityCheckList: "OpportunityCheckList",
  goAndNoGo: "goAndNoGo",
  DealLeadAssign: "DealLeadAssign",
  NDADocument: "NDADocument",
  UUScoring: "UUScoring",
  DDSubmittedByStartUp: "DDSubmittedByStartUp",
  DDAnalysisByGlobus: "DDAnalysisByGlobus",
  DDAssertValueAnalysis: "DDAssertValueAnalysis",
  DDBorrowerRating: "DDBorrowerRating",
  UUScoringAssignPartner:"UUScoringAssignPartner",
  OutPutSummary : "OutPutSummary"
}

export const stagesToSectionMap = {
  OpportunityCheckList: "opportunityChecklist",
  goAndNoGo: "goNoGoSection",
  DealLeadAssign: "assignDealLeadTab",
  NDADocument: "NDADocument",
  UUScoring: "startupUUCompanyScoring",
  DDSubmittedByStartUp: "DDDocumentStartup",
  DDAnalysisByGlobus: "DDAnalysisReport",
  ContractCFSchedule: "ContractCFSchedule",
  DDAssertValueAnalysis: "dueDiligenceAssetValueAnalysis",
  DDBorrowerRating: "DueDiligenceBorrowerRating",
  UUScoringAssignPartner : "UUCompanyScoring",
  OutPutSummary:"outputSummary"
}

export const uuScoringName = {
  StartUp:"Startup",
  DealLead : "Deal Lead",
  Partner : "Partner"
}

export const stagesToTabName = {
  opportunityChecklist: "Opportunity Checklist",
  goNoGoSection: "Go / No Go Section",
  assignDealLeadTab: "Assign Deal Lead Tab",
  NDADocument: "NDA Document",
  startupUUCompanyScoring: "Company Scoring",
  DDDocumentStartup: "DD Document Startup",
  DDAnalysisReport: "DD Analysis Report",
  dueDiligenceAssetValueAnalysis: "Due Diligence Asset Value Analysis",
  DueDiligenceBorrowerRating: "Due Diligence Borrower Rating",
  UUCompanyScoringPartner : "UU Company Scoring Partner",
  outputSummary:"Output Summary",
  pitchDeck: 'Pitch Deck',
  partnersApproval: 'Partners Approval',
  DealLeadUUCompanyScoring: "DealLead UU Company Scoring"
}

export const financialNames = {
  cashExpenses: "cashExpenses",
  creditLines: "creditLines",
  enterpriseValuation: "enterpriseValuation",
  equityCapital: "equityCapital",
  nvpLeases: "nvpLeases",
  outStandingDebt: "outStandingDebt",
  paymentObligations: "paymentObligations",
  revenue: "revenue",
  serviceExpense: "serviceExpense",
  nvpBenefits: "nvpBenefits",
  nvpCashFlow: "nvpCashFlow"
}

export const staticFields = {
  amortization: 'amortization',
  depreciation: 'depreciation',
  operationalExpenditure: 'operationalExpenditure'
}

export const inputParamNames = {
    totalCapex : 'totalCapex',
    equityAmount : 'equityAmount',
    seniorNotesClaim : 'seniorNotesClaim',
    termOfLoan : 'termOfLoan'
}


export const decision = {
  movetoNextStage: "Move to Next stage",
  futureReference: "Save For Future",
  reject: "Pass",
  unArchive: "Unarchive"
}

export const statusMessages = {
  [decision.movetoNextStage]:{
    goAndNoGo: "Move to next Stage was approved by @{userName} on @{date}",
    OpportunityCheckList: "Move to next Stage was approved by @{userName} on @{date}",
    UUScoring: "Move to next Stage was approved by @{userName} on @{date}",
    DDSubmittedByStartUp:  "The @{startup} has uploaded the DD Documents on @{date}",
    DDAnalysisByGlobus: "The Analysis Reports were received on @{date}",
    DDAssertValueAnalysis: "Asset Value Analysis Was completed on @{date}",
    DDBorrowerRating: "Borrower Rating was generated on @{date}",
    OutPutSummary : "@{startup} was successfully added to the portfolio companies by @{userName} on @{date}",
  },
  [decision.futureReference]: {
    goAndNoGo: "@{startup} was saved for the future by @{userName} on @{date}",
    OpportunityCheckList: "This deal was saved for the future by @{userName} on @{date}",
    UUScoring: "This deal was saved for the future by @{userName} on @{date}",
    OutPutSummary : "@{startup} is saved for the future by @{userName} on @{date}",
  },
  [decision.unArchive] : {
    goAndNoGo: "@{startup} was saved for the unArchive by @{userName} on @{date}",
    OpportunityCheckList: "This deal was saved for the unArchive by @{userName} on @{date}",
    UUScoring: "This deal was saved for the unArchive by @{userName} on @{date}",
    OutPutSummary : "@{startup} is saved for the unArchive by @{userName} on @{date}",
  },
  [decision.reject]: {
    goAndNoGo: "This startup was rejected by @{userName} on @{date}",
    OpportunityCheckList: "This deal was rejected by @{userName} on @{date}",
    UUScoring: "This deal was rejected by @{userName} on @{date}",
    OutPutSummary : "This deal was rejected by @{userName} on @{date}",
  }
}

export const saveForFutureOptions = {
  deepStorage : "Deep Storage",
  watchIt : "Watch It" ,
  interestingButWrongTiming : "Interesting But Wrong Timing",
}

export const decisionMapping = {
  WON: "Move to Next stage",
  TIE: "TIE",
  LOST: "Pass",
  noGo: "Pass",
  go: "Move to Next stage"
}

export const mode = {
  filled: "filled",
  form: "form",
  view: 'view'
}

export const detailsAwaitingMessages = {
  startUp: 'Awaiting Due Diligence Documents from the Startup.',
  globus: 'Awaiting Due Diligence Documents from the Actyv.',
  startUpUUScoring: 'Awaiting UUScoring from the Startup.',
}

export const alerts={
  dealLeadAssigned : "Deal Lead Assigned Successfully",
  uuScoringPartnerAssigned : "Partner Invited for UUScoring Successfully",
  evlRangeOutOfBound : "Please check the Values, LTVE Rating Value Range is Out of Bound",
  lbOutOfBound : "Please check the Values, Liquidity Burn Rating Value Range is Out of Bound",
  savedSuccessfully : "Saved Successfully",
  submittedSuccessfully : "Submitted Successfully",
  fillAllFields : "Kindly Fill All the Fields to Submit",
  tryLater:"Unable To Process Your Request Try After Sometime.",
  defaultMessage : "This Deal was successfully Added to Portfolio By ",
  addComment : "Kindly Add comment To Proceed Further.",
  selectDecision : "Kindly Select Your Decision To Proceed Further.",
  selectSaveForFutureOptions : "Kindly Select a Save For Future Option To Proceed Further.",
  comingSoon :"This Feature is Coming Soon ...",
  pleaseEnterValidEmail :"Kindly enter valid Email Address",
  uploadedDocumentSuccessfully : "Document Uploaded Successfully",
  provideValidInputToComment: "Please Provide Valid Input Before Trying to Add Comment",
  invalidFileType: "File Type Should Be PDF, PPT or DOC Only",
  invalidFileSize: "File Size Should Be Less Than 5MB",
  selectDealLead: "Kindly Select Deal Lead before Proceeding",
  decisionUpdateSuccess: "Decision Updated Successfully",
  npvDetailsMissing: "Kindly Enter NPV Details",
  missingDDReport: "Kindly Upload The Report Document Before Proceeding",
  partnerRequired: "Kindly Select a Partner to Proceed",
  cancelledUpload: "Upload Cancelled by User.",
  confirmReportUpload: "Once submitted you won't be able to upload another file, Are you sure you want to proceed?",
  ddInformationIncorrect: "Decimal values are not accepted",
  ddInformationEmpty: "Fields can not be empty in contract notes", 
  totalCapex: "Enter Total Capex",
  termOfLoan: "Enter term of Loan",
  outStandingDebt: "Fields in Enterprise Value Leverage can not be empty",
  paymentObligations: "Fields in Enterprise Value Leverage can not be empty",
  nvpLeases: "Fields in Enterprise Value Leverage can not be empty",
  enterpriseValuation: "Fields in Enterprise Value Leverage can not be empty",
  cashExpenses: "Fields in Liquidity Burn can not be empty",
  creditLines: "Fields in Liquidity Burn can not be empty",
  equityCapital: "Fields in Liquidity Burn can not be empty",
  serviceExpense: "Fields in Liquidity Burn can not be empty",
  revenue: "Fields in Liquidity Burn can not be empty",
  equityAmount: "Fields in Liquidity Burn can not be empty",
  seniorNotesClaim: "Fields in Liquidity Burn can not be empty",
  oppListCommentCheck: "Please provide valid input Before trying to Add comment",
  uploadAllDDDocuments: "Upload all Due Diligence Document",
  weightSumBound: "Combined Weight for UU Scoring, EVL and Liquidity Burn should be 100%.",
  blendedScoreError: "Blended Score Error",
  pipelineMoveSuccess: "Application Moved to Open Pipeline Successfully",
  pipelineMoveFail: "Unable to Move Application. Please try again later.",
  fileUploadError: "File Upload Process failed, Please try again later."
}

export const userRoles = {
  GLOBUS : "GLOBUS",
  URBANUS : "URBAN US"
}


export const OPPORTUNITY_FAIL_THRESHOLD = 1;
export const OPPORTUNITY_WATCH_THRESHOLD = 3;

export const UUScoringThreshold = 2.5

export const dashboardPages = {
  invoiceScreen: '/invoice',
  salesScreen: '/sales',
  quotationScreen: "/quotation",
  customerScreen: '/customers',
  reportScreen: '/reports',
  settingsScreen: '/settings',
  subscriptionScreen: '/subscriptions',
  productsScreen: '/products',
}

export const MenuItems = {
  OVERVIEW: "Overview",
  FILES: "Document",
  FOLDER: "Folder",
  ACTIVITIES: "Activities",
  NOTES: "Notes",
  ASSESSMENT: "Assessment",
  DOCUMENT: "Document",
  LOANS: "Loans",
  FINANCIAL_MODEL: 'Financial Model',
  CONTACTS: "Contacts",
  SUMMARY: "Summary",
  WORKSPACE: "Workspace",
  DEAL_INFO: "Deal Information",
  COMPANY_INFO: "Company Information"
}

export const commonFormFields = {
  NAME: "name",
  EMAIL: "email",
  PASSWORD: "password",
  PHONE: "phone",
  EMPTY: "empty"
}

export const VERTICAL_LIST_PLACEHOLDER = "Sector";

export const trackActions = {
  SUBMIT: 'SUBMIT',
  SAVE: 'SAVE',
  FETCH: 'FETCH',
  INVITE: 'INVITE',
  DELETE: 'DELETE',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  FORGOT: 'FORGOT',
  ARCHIVE: 'ARCHIVE',
  SIGNUP: 'SIGNUP',
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  SEND: 'SEND',
  GENERATE: 'GENERATE',
  DOWNLOAD: 'DOWNLOAD',
  UPLOAD: 'UPLOAD',
  PARSE: 'PARSE',
}

export const CEOForm = {
  STATE : "state",
  COUNTRY : "country",
  SECTOR : "sector", 
  ADD_COMPANY_DETAILS : "Add Company Details",
  CEO: "CEO",
  FORM_NAME :"error.formName",
  VALID_CEO_FORM : "error.isValidCEOForm",
  COMPANY_DETAILS : "Company Details",
  LOCATION_DETAILS: "Location Details"
}


export const FILE_UPLOAD_SCREEN = {
  GOOGLE_CONNECT : "Connect To Google Drive",
  DRIVE_ACCOUNT_MESSAGE: "We Will Open a New Page To Help You Connect Your Google Drive Account",
  DROPBOX_CONNECT : "Connect To Drop Box",
  DROPBOX_ACCOUNT_MESSAGE: "We Will Open a New Page To Help You Connect Your Drop Box Account",
  DRAG_FILE: "Drag files here",
  OR: "- or -",
  CHOOSE_FILES: "Choose Files",
  SEARCH: "Search",
  MY_COMPUTER: "My Computer",
  LINK: "DataRoom URL",
  GOOGLE_DRIVE: "Google Drive",
  DROP_BOX: "Drop Box",
  CLEAR_ALL: "Clear All",
  UPLOAD_SCREEN: "Upload File",
  UPLOAD_TYPE: "uploadType",
  FILES :"files",
  ERROR: "error",
  MESSAGE: "message",
  FILE_UPLOAD_ERROR: "Error in Uploading Document",
  FILE_UPLOAD_SUCCESS: "FILE UPLOADED SUCCESSFULLY",
  SUCCESS: "success",
  GOOGLE_DRIVE_ERROR_MESSAGE: "ERROR IN FETCHING DOCUMENTS FROM GOOGLE DRIVE",
  INVALID_FILE_URL_ERROR: "Kindly Provide Valid File URL",
  SEND_NOTIFICATION: "Send Email Notification"
}

export const reportGeneratorData = {
  inputsFormat: {
    debtAmount: 'currency',
    equityAmount: 'currency',
    totalCapex: 'currency',
    collateralValue: 'percent',
    interestRate: 'percent',
    lTVConstraint: 'percent',
    portfolioAdjustmentSpread: 'percent',
    residualRefinanceShare: 'percent',
    revenueShare: 'percent',
    interestOnlyPeriod: 'months',
    term: 'months',
    termOfLoan: 'months',	
  },
  outputsFormat: {
    moic: 'times',
    irr: 'percent',
    interestCoverageCheck: true,
    dSCRCoverageCheck: true,
    months: true
  },
  inputMappings: {
    debtTerms: ['term', 'interestRate', 'interestOnlyPeriod', 'debtAmount'],
    equityAssumptions: ['portfolioAdjustmentSpread', 'residualRefinanceShare', 'revenueShare'],
    debtSizingConstraints: ['lTVConstraint', 'dSCRConstraint', 'collateralValue'],
    startUpInputs: ['termOfLoan', 'equityAmount', 'totalCapex']
  },
  projectionLabels: ['Total Income', 'Total Outflow', 'Net Cash flow', 'EBITDA Values'],
  returnMetricsLabels: ['Debt', 'Equity + Debt']
}

export const activityStageMapping = {
  "goAndNoGo": "Pre Screening",
  "OpportunityCheckList": "Opportunity Checklist",
  "UUScoring": "UU Scoring",
  "DDSubmittedByStartUp": "Due Diligence",
  "DDAnalysisByGlobus": "Due Diligence Analysis By Actyv",
  "OutPutSummary": "Output Summary"
}

export const activityBlackList = ["DDBorrowerRating", "DDAssertValueAnalysis", "DealLeadAssign", ""]

export const activityDecisionMapping = {
  "Pass": "Rejected",
  "Move to Next stage": "Approved",
  "Save For Future": "Saved for Future",
  "Archive": "Archived"
}

export const DEFAULT_APPLICATION_PRIORITY = "Normal";

export const priorityLabels = ["Very Low", "Low", "Normal", "Moderate", "High"];

export const COMPANY_DATA_PREFIX = "test-images/msme/UUCompanyData/";

export const TAB_HEADINGS = {
  rejected: "Rejected Applications",
  leads: "Leads",
  futureReference: "Future Reference Applications",
  archive: "Archived Applications",
  open: "Open Applications"
}

export const stageLabels = {
  OpportunityCheckList: "Opportunity Checklist",
  UUScoring: "Company Scoring",
  initialModel: "Initial Model/Structure",
  termSheet: "Term Sheet",
  DueDiligence: "Due Diligence",
  initialInvestment: "Initial Memo",
  finalInvestment: "Final Memo",
  Open: "Pre-screening",
  goAndNoGo: "Pre-screening",
  OutPutSummary: "Definitive Documentation"
}

export const mimeTypes = {
  "word": ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text",
  "excel": ".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet,",
  "pdf": ".pdf, application/pdf,",
  "ppt": ".ppt, .pptx, application/vnd.ms-powerpoint,",
  "images": "image/jpg,image/jpeg,image/png,"
}

export const acceptedDocumentTypes = `${mimeTypes.pdf} ${mimeTypes.excel} ${mimeTypes.word} ${mimeTypes.ppt} `
