import React, { Component, Fragment } from 'react';

import "./style.css"

export default class Pricing extends Component {

    state = {
        recommendPackage: "Professional",
        pricingData: [
            {
                plan: "Basic",
                price: "3599",
                list: [
                    "12 Months",
                    "Unlimited Invoicing",
                    "12 Months @ 299.91 per Month + 18% GST",
                ]
            },
            {
                plan: "Professional",
                price: "4999",
                list: [
                    "24 Months",
                    "Unlimited Invoicing",
                    "24 Months @ 208.29 per Month + 18% GST",
                ]
            },
            {
                plan: "Master",
                price: "8499",
                list: [
                    "60 Months",
                    "Unlimited Invoicing",
                    "60 Months @ 141.65 per Month + 18% GST",
                ]
            }
        ]
    }


    componentDidMount() {
    }

    renderCard = (cardDetails = {}, recommendPackage) => {
        const { plan, list = [], price } = cardDetails;
        return <div className={`${recommendPackage === plan ? "card active" : "card shadow"}`}>
            <ul>
                <li className="pack">{plan}</li>
                <li id="basic" className="price bottom-bar">₹{price}</li>
                {list.map(item => <li class="bottom-bar">{item}</li>)}
                <li><button className={`${recommendPackage === plan ? "active-pricing-btn pricing-btn" : "pricing-btn"}`}>Buy Now</button></li>
            </ul>
        </div>
    }
    renderCards = () => {
        const { pricingData, recommendPackage } = this.state;
        return <div class="cards" id="pricing">
            {pricingData.map(item => this.renderCard(item, recommendPackage))}
        </div>
    }

    renderHeader = () => {
        return <header className="flex">
            <h1>Our Pricing</h1>
            <div class="toggle">
                <label>Annually </label>
                <div class="toggle-pricing-btn">
                    <input type="checkbox" class="checkbox" id="checkbox" />
                    <label class="sub" id="sub" for="checkbox">
                        <div class="circle"></div>
                    </label>
                </div>
                <label> Monthly</label>
            </div>
        </header>
    }

    renderSupportTalk = () => {
        return <div className="div-talk-to-us">
            <div className="query-circle one-line">
                Talk To Us <br></br>  on   <br></br>  8344609036
            </div>
            <div className="triangle-down"></div>
        </div>
    }
    render() {
        return (
            <Fragment>
                <div className="layout-alignment">
                    <div>
                        <div className="">
                            {this.renderHeader()}
                            {this.renderCards()}
                        </div>
                    </div>
                    {this.renderSupportTalk()}
                </div>
            </Fragment>
        )
    }
}
