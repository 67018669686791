import _ from 'lodash';
import React, { Component } from 'react';
import './App.css';
import ErrorBoundary from './Components/ErrorBoundary';
import Navigator from './Components/Navigator';
import { I18nProvider, LOCALES } from './i18n';


import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

export default class App extends Component {
  state = {
    language: LOCALES.ENGLISH,
    theme: ''
  }

  setLanguage = (localLanguage) => {
    this.setState({
      language: localLanguage
    })
  }

  //add theme 

  render() {
    return (
      <ErrorBoundary>
        <I18nProvider locale={this.state.language}>
          <Navigator />
        </I18nProvider>
      </ErrorBoundary >
    );
  }
}