import React, { Component } from 'react';
import './../../styles.css'
import TextInput from '../../../../Components/FormComponents/TextInput'
import Button from '../../../../Components/FormComponents/Button';
import { signUpPayload } from '../../Components/Forms/formPayload';
import Loader from '../../../../utils/loader';
import { signUp } from '../../../../utils/endPoints';
import { getOrganizationID } from '../../../../utils/utils';
import { isInvalid } from '../../../../utils/validations';
import { commonFormFields } from '../../../../utils/enums';
import { showGrowlMessage, renderGrowlUI } from '../../../../Services/index';

export default class UserDetails extends Component {

    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        phone: "",
        loader: false,
        shortName: "",
        error: {
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            password: false,
            role: false,
            passwordMismatch: false
        }
    }

    onValueChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSignUp = () => {

        const userDetails = this.getUserDetailsFromState();

        const userDetailErrorMessage = this.validateUserBasicDetails(userDetails);

        this.showFormFieldErrorMessage(userDetailErrorMessage);

        const listOfUserErrorMessage = this.formatUserErrorMessage(userDetailErrorMessage);

        if (this.validateFormFields(listOfUserErrorMessage)) return;

        this.userSignUpTrigger();
    }

    getUserDetailsFromState = () => {
        const { firstName, lastName, email, password, passwordConfirm, phone, roleId, organizationId, shortName, token } = this.state;

        return { firstName, lastName, email, password, passwordConfirm, phone, roleId, organizationId, shortName, token };
    }

    showFormFieldErrorMessage = (userDetailErrorMessage) => {

        const { firstNameError, lastNameError, emailError, passwordError, passwordMismatch, phoneError } = userDetailErrorMessage;

        this.setState({ error: { firstName: firstNameError, lastName: lastNameError, email: emailError, password: passwordError, role: false, phone: phoneError, passwordMismatch } });
    }

    validateUserBasicDetails = (userDetails) => {

        const { firstName, lastName, email, password, passwordConfirm, phone } = userDetails;

        let firstNameError = isInvalid(commonFormFields.NAME, firstName);

        let lastNameError = isInvalid(commonFormFields.NAME, lastName);

        let emailError = isInvalid(commonFormFields.EMAIL, email);

        let passwordError = isInvalid(commonFormFields.PASSWORD, password);

        let phoneError = isInvalid(commonFormFields.PHONE, phone);

        let passwordMismatch = this.validateConfirmPassword(password, passwordConfirm);

        return { firstNameError, lastNameError, emailError, passwordError, passwordMismatch, phoneError };
    }

    formatUserErrorMessage = (userDetailErrorMessage) => {

        const { firstNameError, lastNameError, emailError, passwordError, passwordMismatch, phoneError } = userDetailErrorMessage;

        return [firstNameError, lastNameError, emailError, passwordError, passwordMismatch, phoneError];
    }

    validateFormFields = (listOfFormFields) => listOfFormFields.includes(true);

    validateConfirmPassword = (currentPassword, confirmPassword) => currentPassword !== confirmPassword

    userSignUpTrigger = async () => {

        const { firstName, lastName, email, password, phone, roleId, organizationId, token } = this.getUserDetailsFromState();

        const payload = { firstName, lastName, email, password, roleId, phone, organizationId };

        this.setState({ loader: true });

        const response = await signUp(payload, token);

        if (response && response.status) {
            this.setState({ firstName: '', email: '', lastName: '', password: '', loader: false, phone: '', passwordConfirm: '' });
            showGrowlMessage({ growlMsg: response.message, growlStatus: "success", updateState: this.updateState });
            setTimeout(() => {
                window.location.href = `${window.location.origin}/login`;
            }, 2000);
            return;
        }

        this.setState({ loader: false });

        if (response && response.code === 401) return showGrowlMessage({ growlMsg: "Access Denied", growlStatus: "error", updateState: this.updateState });

        showGrowlMessage({ growlMsg: "User already Exists!", growlStatus: "error", updateState: this.updateState });

    }
    renderInputFields = (fields) => {
        const { name, type, value, label, placeholder, errorState, errorMsg } = fields;
        return <div className='common-field-margin-top'>
            <TextInput name={name} type={type} value={value} label={label} isImportant={true} onChange={(e) => this.onValueChange(e)} placeholder={placeholder} />
            {errorState ? <div className='Error'>{errorMsg}</div> : null}
        </div>
    }
    renderAngerTag = (text) => <span className='common-link-TAndC '>{text}</span>

    renderWrapper = (text, heading) => {
        return <div className='TAndC-wrapper'>
            <p className='common-text-TAndC '>
                {text}
                {this.renderAngerTag(heading)}
            </p>
        </div>
    }

    renderWelcomeText = () => {
        return <div className='login-text-container'>
            <p className='new-here'> Create your free account, for hassle free onboarding!</p>
        </div>
    }
    componentDidMount() {

        this.setState({ organizationId: getOrganizationID() })

        if (this.props.payload) {

            const { firstName, lastName, email, password, phone } = this.props.payload.state;

            this.setState({
                firstName, lastName, email, password, passwordConfirm: password, phone
            });
        }

    }


    updateState = (state) => this.setState(state);

    render() {

        const signUpFrom = signUpPayload(this.state);

        const { loader } = this.state;

        return (
            <div>
                {renderGrowlUI(this.state, this.updateState)}
                <div className="flexLoginBox">
                    <div className="flexLoginHalfWidthBox left">
                        <img className='login-image-logo' src={require('../../../../assets/images/loginImage.png').default} />
                    </div>
                    <div className="flexLoginHalfWidthBox right">
                    <div className='login-form'>
                            <p className='text-login-Welcome-header'>Create your account</p>
                            {this.renderWelcomeText()}
                            {signUpFrom.map(field => this.renderInputFields(field))}
                            {loader ? <Loader /> : <div className='common-button-margin-top'>
                                <Button name={"Sign Up"} onPress={() => { this.handleSignUp() }} />
                            </div>}

                            <div className="common-field-margin-top">
                                <p className="css-1h15lvr">Back to login page? <a href="/login"><span className="css-evbtna">Login</span></a></p>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        );
    }
}