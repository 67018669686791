import React , { Fragment }from 'react'
import { Redirect } from 'react-router-dom';
import { getToken } from '../../utils/reduxUtils';

import Header from "../../Components/Header/index";

import SideNav from "../../screens/Dashboard/SideNav/index";

import {  dashboardPages } from '../../utils/enums';

class ProtectedRoute extends React.Component {

    switchTab = (tabPath) => {
        this.setState({ tabToRender: tabPath }, () => {
            const currentPath = window.location.pathname;
            if (Object.values(dashboardPages).includes(currentPath)) this.setState({ currentPath });
        });
    }

    render() {
        const Component = this.props.component;

        const isAuthenticated = getToken();

        return isAuthenticated ? (
            <Fragment>
                <Header switchTab={this.switchTab} />
                <SideNav context={this} />
                <Component />
            </Fragment>

        ) : (
                <Redirect to={{ pathname: '/login' }} />
            );
    }
}

export default ProtectedRoute;