import { load } from 'recaptcha-v3';

const SITE_KEY = "6Ldk50caAAAAAC4Po_ObSChtRU0XQtTqTzAbFMPp";

export const fetchTokenFromReCaptchaV3 = async () => {

  try {

    const captchaScript = await load(SITE_KEY, {
      autoHideBadge: true
    });
    const captchaToken = await captchaScript.execute(SITE_KEY);

    return captchaToken;

  } catch (error) {

  }
}