import React, { Component, Fragment } from 'react';

import "./style.css"

import DataTable from '../../Components/DataTable/index';

import { getProductColumns , generateProductDetailsPayload } from "./formPayload";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-bootstrap/Modal';

import { renderInputFields } from '../../Components/InputComponents/index';

import { renderDropDown } from '../../screens/Registration/Components/service/index';

import { renderGrowlUI, showGrowlMessage } from '../../Services/index';

import { updateProducts, fetchProducts } from '../../utils/endPoints';

export default class Products extends Component {

    state = {
        page: 1,
        newPerPage: 10
    }


    componentDidMount = () => this.triggerListOfProducts();

    triggerListOfProducts = () => {

        const { newPerPage, page } = this.state;

        this.fetchUsers(newPerPage, page);
    }

    applyFilter  = () => {
        const { searchTerm } = this.state;

        if (!searchTerm) return showGrowlMessage({ growlMsg: "Search Products By Product Name", growlStatus: "error", updateState: this.updateState });

        this.triggerListOfProducts();
    }


    // axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);

    fetchUsers = async (newPerPage, page) => {

        this.setState({ loading: true });

        const { searchTerm } = this.state;

        const { result = [], totalRows = 10 } = await fetchProducts({ pageNumber: page, limit: newPerPage , searchTerm });

        this.setState({ data: result, totalRows, loading: false });
    }

    onChangePage = (page) => {
        this.fetchUsers(10, page);
    }

    onChangeRowsPerPage = (newPerPage, page) => {
        this.fetchUsers(newPerPage, page);
    }

    onFilterValueChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    onValueChange = (event) => { this.setState({ [event.target.name]: event.target.value }) };

    renderProductForm = (inputType) => {
        const inputConfig = {
            ["input"]: this.renderInputUI,
            ["dropdown"]: this.renderDropDownUI
        }
        return inputConfig[inputType] || renderInputFields
    }

    renderDropDownUI = (field) => {
        const { name, value, label, placeholder, values , important } = field;
        return <div className="common-field-margin-top">
            {renderDropDown({
                name: name, type: "text", value: value, label: label, placeholder: placeholder,
                values: values, onValueChange: this.onValueChange , important,
            })}
        </div>
    }

    renderInputUI = (field) => {
        const { name, type, value, label, placeholder, important } = field;
        return renderInputFields({ name: name, type: type, value: value, label: label, placeholder, important }, this.onValueChange)
    }

    updateProductDetails = async () => {

        const isProductValidData = this.validateProductDetails(this.state);

        if (isProductValidData) return showGrowlMessage({ growlMsg: "Kindly Provide All the Mandatory Fields", growlStatus: "error", updateState: this.updateState });

        this.updateState({ visible: false, loading: true });

        try {

            const { productName = "", productStatus = "", productPrice = "", productQuantity = "", unit = "" , isEdit , productId = "" } = this.state;

            const payload = { productName, productStatus, productPrice, productQuantity, unit , isEdit , productId };

            const { status = false , message = ""} = await updateProducts(payload);

            if (status) {
                this.triggerListOfProducts();
                return showGrowlMessage({ growlMsg: "Product Created Successfully", growlStatus: "success", updateState: this.updateState });
            }

            showGrowlMessage({ growlMsg: message, growlStatus: "error", updateState: this.updateState });

        } catch (error) {
            showGrowlMessage({ growlMsg: "Unable to Create Product", growlStatus: "error", updateState: this.updateState });
        } finally {
            this.clearAllFormFields();
        }
    }

    validateProductDetails = (state) => {

        const { productName = "", productStatus = "", productPrice = "", productQuantity = "", unit = "" } = state;

        const mandatoryList = [productName, productStatus, productPrice, productQuantity, unit];

        return mandatoryList.includes("");
    }

    updateState = (state) => this.setState(state);

    clearAllFormFields = () => {
        this.updateState({ isEdit : false , loading: false , visible: false, productId: "", productName: "", productStatus: "", productPrice: "", productQuantity: "", unit: "" });
    }

    renderUploadModel = () => {

        const { visible , isLoading = false , productId } = this.state;

        return <Modal id="model" show={visible} size="lg" centered effect="fadeInUp"  onHide={() => this.clearAllFormFields()}>
            {renderGrowlUI(this.state, this.updateState)}
            <div className="model-file-upload-height">
                <div className="model-file-upload-header-1">
                    <div className="invoiceInfoHeading">PRODUCT</div>
                    <div className="invoiceInfoHeading" onClick={() => this.clearAllFormFields()}><a>X</a></div>
                </div>
                <div className="model-file-upload-flex flex-col">
                    <div className="login-form extraPadding">
                        {generateProductDetailsPayload({ state: this.state, onValueChange: this.onValueChange }).map(eachField => this.renderProductForm(eachField.inputType)(eachField))}
                    </div>
                    <div className="addNewProductButtonUI bottomUI">
                        <button className="addNewProductButton" onClick={() => this.clearAllFormFields()}> Cancel</button>
                        <button className="addNewProductButton" onClick={() => !isLoading && this.updateProductDetails()}>
                            <div> {productId ? "Update" : "Save"}</div>{isLoading && <div className='lds-dual-ring loaderMargin'></div>}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    }

    onEdit = (row) => {
        const { productId, productName, productPrice, productQuantity, productStatus, unit } = row;
        this.setState({ productId, productName, productPrice, productQuantity, productStatus, unit, visible: true , isEdit : true });
    }
    render() {
        const { data = [] , totalRows = 0 , loading = false } = this.state;
        return (
            <Fragment>
                {this.renderUploadModel()}
                {renderGrowlUI(this.state, this.updateState)}
                <div className="layout-alignment dataTableMargin" id="dataTable">
                    <div className="addNewProductButtonUI">
                        <h2> Products</h2>
                        <button className="addNewProductButton" onClick={() => this.setState({ visible: true, isEdit: false })}> <FontAwesomeIcon icon={faEdit} /> Add New Product</button>
                    </div>
                    <div className="">
                        <DataTable title={`Manage Products (${totalRows})`}
                            onFilterValueChange={this.onFilterValueChange} selectableRows={true} data={data} columns={getProductColumns({ onEdit: this.onEdit })} totalRows={totalRows}
                            loading={loading} onChangePage={this.onChangePage} applyFilter={this.applyFilter}
                            refreshFilter={this.triggerListOfProducts} subHeader={true}
                            searchBy={"Products"}
                            onChangeRowsPerPage={this.onChangeRowsPerPage} pagination={true} />
                    </div>
                </div>
            </Fragment>
        )
    }
}
